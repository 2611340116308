.dialogWrapper {
    padding: 16px 16px 40px 16px;
}

.closeBtn {
    text-align: right;
}

.contentWrapper {
    margin: 0 8px;
}

.avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px 0;
}

p.name {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

p.email {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
}

.memberWrapper {
    text-align: left;
    border-top: 1px solid #C9C5CA;
    padding-top: 24px;
    margin-top: 24px;
}

.noneMemberWrapper {
    width: 110px;
}

p.memberTitle {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

p.memberDescription {
    color: #939094;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

p.joinBtnText {
    color: #585DE9;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

p.joinBtnText:hover {
    text-decoration-line: underline;
}

p.logoutBtnText {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-top: 20px;
    cursor: pointer;
    display: inline-block;
}

p.logoutBtnText:hover {
    text-decoration: underline;
}

.subscibeStatusChip {
    font-weight: bold;
    font-size: 12px;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.title {
        padding: 0 47px;
    }

    p.subTitle {
        padding: 0 13px;
    }
}