:root {
  --primaryColor: #585de9;
  --primaryDarkColor: #1d1d2f;
  --secondaryColor: linear-gradient(100deg, #575de8 20%, #8050dd);
}

/* Background Colors */

.banner {
  background-color: var(--primaryDarkColor);
  color: #fff;
}

.bannerLogo {
  background-color: var(--primaryDarkColor);
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid #ffffff;
}

.artStoreBannerLogo {
  margin-top: 48px;
  height: 24px;
}

h2.bannerHeadline {
  font-style: normal;
  color: #ffffff;
  font-size: 28px;
  line-height: 36px;
  padding: 0 20px;
  margin-top: 36px;
}
h2.bannerHeadline span {
  display: block;
  font-family: 'Samsung Sharp Sans', sans-serif !important;
}

p.bannerSubHeading {
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  padding: 0 40px;
  margin-bottom: 48px;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .artStoreBannerLogo {
    margin-top: 56px;
  }
  h2.bannerHeadline {
    font-size: 56px;
    line-height: 64px;
    padding: 0 10px;
  }
  p.bannerSubHeading {
    font-size: 16px;
    line-height: 24px;
    margin-top: 36px;
    padding: 0 100px;
  }
  p.bannerCaption {
    font-size: 16px;
    line-height: 24px;
    margin-top: 36px;
    padding: 0 127px;
    margin-bottom: 56px;
  }
  .btn {
    width: 20%;
  }
  .footer {
    padding: 4rem;
  }
  .subscriptionContainer {
    padding: 4rem;
  }

  .carouselCaption {
    width: 50%;
    margin-top: 1rem !important;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .artStoreBannerLogo {
    margin-top: 78px;
    height: 36px;
  }
  h2.bannerHeadline {
    padding: 0 112px;
    margin-top: 48px;
  }
  p.bannerSubHeading {
    margin-top: 48px;
    padding: 0;
  }
  p.bannerSubHeading span {
    display: block;
  }
  p.bannerCaption {
    font-size: 22px;
    line-height: 28px;
    margin-top: 48px;
    padding: 0 112px;
    margin-bottom: 78px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  h2.bannerHeadline {
    padding: 0 275px;
  }
  p.bannerSubHeading {
    margin-top: 48px;
  }
  p.bannerCaption {
    font-size: 22px;
    line-height: 28px;
    margin-top: 48px;
    padding: 0 275px;
    margin-bottom: 78px;
  }
}

/**
*** EXTRA LARGE ***
**/
@media screen and (min-width: 1536px) {
  h2.bannerHeadline {
    padding: 0 300px;
  }
  p.bannerSubHeading {
    margin-top: 48px;
    padding: 0 300px;
  }
  p.bannerCaption {
    font-size: 22px;
    line-height: 28px;
    margin-top: 48px;
    padding: 0 300px;
    margin-bottom: 78px;
  }
}
