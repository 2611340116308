.container {
  overflow: hidden;
  padding: 0;
  margin: 24px 0;
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: var(--aspect-ratio);
  object-fit: cover;
  border-radius: 8px;
}
.container iframe {
  border: none;
}

.container:hover > .ytEmbed > .ytPlayButtonWrapper {
  transition: all 1s ease;
  background-color: rgba(88, 93, 233, 1);
}

.ytEmbed {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}
.ytEmbed.ytLoaded::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.ytEmbed::after {
  content: '';
  display: block;
  padding-bottom: var(--aspect-ratio);
}
.ytEmbed > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ytEmbed > .ytPlayButtonWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: rgba(88, 93, 233, 0.7);
  border-radius: 50%;
  width: 10.5vw;
  height: 10.5vw;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2vw;
}

.ytEmbed > .ytPlayButton {
  background: none;
}

.ytEmbed.ytLoaded {
  cursor: unset;
}

.ytEmbed > .ytLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 12.5vw;
  height: 12.5vw;
  padding: 1vw;
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .ytEmbed > .ytPlayButtonWrapper {
    width: 10.5vw;
    height: 10.5vw;
    padding: 2vw;
  }

  .ytEmbed > .ytLoader {
    width: 12.5vw;
    height: 12.5vw;
    padding: 1vw;
  }

  .container {
    border-radius: 16px;
  }
}

/** 
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .ytEmbed > .ytPlayButtonWrapper {
    width: 8.5vw;
    height: 8.5vw;
    padding: 1.8vw;
  }

  .ytEmbed > .ytLoader {
    width: 9.5vw;
    height: 9.5vw;
    padding: 1.2vw;
  }

  .container {
    border-radius: 16px;
  }
}

/** 
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .ytEmbed > .ytPlayButtonWrapper {
    width: 7.3vw;
    height: 7.3vw;
    padding: 1.6vw;
  }

  .ytEmbed > .ytLoader {
    width: 7.3vw;
    height: 7.3vw;
    padding: 1.2vw;
  }

  .container {
    border-radius: 16px;
  }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
  .ytEmbed > .ytPlayButtonWrapper {
    width: 6vw;
    height: 6vw;
    padding: 1.4vw;
  }

  .ytEmbed > .ytLoader {
    width: 6vw;
    height: 6vw;
    padding: 1vw;
  }

  .container {
    border-radius: 16px;
  }
}
