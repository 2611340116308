.footer {
  color: white;
  padding: 48px 32px;
}

.footerLink {
  margin: 0;
  padding: 0;
}

.footerLink li {
  list-style-type: none;
  padding: 0.25rem 0;
}

.footerLinkItem {
  text-decoration: none;
  text-decoration-skip-ink: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.footerLinkItem:hover {
  text-decoration: underline;
}

.linksHeading {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #cbcddc;
  opacity: 0.7;
  padding-bottom: 8px;
}

p.footerCopyright,
div.secondaryNav a.navLink {
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #9a99b0;
}

p.footerCopyright {
  margin: 24px 0;
}

p.footerCopyright a {
  color: inherit;
  text-decoration-thickness: 0.1px;
}

div.secondaryNav a.navLink {
  text-decoration: none;
  text-decoration-skip-ink: none;
  margin-right: 20px;
}

div.secondaryNav a.navLink:hover {
  text-decoration: underline;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .footer {
    padding: 48px 64px;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .footer {
    padding: 48px 112px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .footer {
    padding: 48px 200px;
  }
}

/**
*** EXTRA LARGE ***
**/
@media screen and (min-width: 1536px) {
  .footer {
    padding: 48px 204px;
  }
}
