.frameContainer {
  padding: 40px 34px;
  text-align: center;
}

.frameBody {
  border-radius: 8px;
  overflow: hidden;
}

.frameImageContainer {
  height: 200px;
}

.frameImageContainer section {
  height: 100%;
  width: 100%;
}
.frameImage {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
}

.frameContent {
  background-color: #fff;
  padding: 32px 24px;
}

h5.frameContentHeading {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

p.frameContentCaption {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-top: 8px;
}

.btnWrapper {
  background-color: var(--primaryColor);
  margin: 32px auto auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  width: 102px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.btnWrapper:hover,
.btnWrapper:focus {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #585de9;
}

.btnText {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .frameContainer {
    padding: 56px 32px;
  }
  .frameBody {
    height: 250px;
    border-radius: 16px;
  }
  .frameImageContainer {
    height: 250px;
  }
  .frameContent {
    text-align: left;
    padding: 33px 32px;
  }
  h5.frameContentHeading {
    font-size: 26px;
    text-align: left;
  }
  p.frameContentCaption {
    text-align: left;
  }
  .btnWrapper {
    margin: 34px 0 0;
  }
}

/**
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .frameContainer {
    padding: 64px 112px;
  }
  .frameBody {
    height: 280px;
  }

  .frameImageContainer {
    height: 280px;
  }
  .frameContent {
    padding: 36px 32px;
  }
  h5.frameContentHeading {
    font-size: 28px;
  }
  p.frameContentCaption {
    font-size: 16px;
    line-height: 24px;
  }
  .btnWrapper {
    margin: 24px 0 0;
  }
}

/**
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .frameContainer {
    padding: 64px 212px;
  }
  .frameBody {
    width: 856px;
    margin-left: auto;
    margin-right: auto;
  }
}

/**
  *** EXTRA LARGE ***
  **/
@media screen and (min-width: 1536px) {
  .frameContainer {
    padding: 64px 339px;
  }
  .frameBody {
    height: 320px;
    border-radius: 12px;
  }
  .frameImageContainer {
    height: 320px;
  }
  .frameContent {
    padding: 56px 48px;
  }
}
