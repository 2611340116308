.container {
  line-height: 2;
  padding: 2rem;
  padding-left: 1rem;
  margin-top: 2rem;
}

.container h1,
.container h2 {
  line-height: 1.5;
}

.container li {
  margin-bottom: 1rem;
}

.listTypeNone {
  list-style: none;
}

.pdRight1rem {
  padding-right: 1rem;
}

.alphabetBullets {
  list-style-type: lower-alpha;
}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
  .container {
    padding: 0 2rem 4rem;
  }
}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
  .container {
    padding: 0 4rem 6rem;
  }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
  .container {
    padding: 64px 212px;
  }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
  .container {
    padding: 64px 340px;
  }
}
