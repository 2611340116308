.containerWrapper {
    overflow: hidden;
}

.nineBySixteen .detailsContainerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    position: relative;
    padding: 40px 20px 64px;
    text-align: center;
    overflow: hidden;
}

.subtitleSection {
    text-align: left;
    padding-top: 4px;
}

.title {
    padding: 4px 0;
    text-align: left;
}

p.heading {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

p.subHeading {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.icon {
    margin-left: 10px;
    cursor: pointer;
}

.detailsSection {
    margin-top: 16px;
    text-align: left;
}

p.descriptionHeading {
    font-weight: 700;
    font-size: 14px;
}

p.description {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

h4.header {
    margin-bottom: 16px;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
}

.imgContainer {
    overflow: hidden;
    margin-bottom: 20px;
}

.imgContainer img {
    display: block;
}

.sixteenByNine .imgContainer img {
    width: 100%;
    height: auto;
}

.nineBySixteenContainer {
    display: none;
}

.nineBySixteenLargeContainer {
    display: none;
}

.nineBySixteen .imgContainer img {
    margin: auto;
    width: 100%;
}

.nineBySixteen .imgContainer {
    margin: 0 auto 16px;
}

.imgWrapper {
    overflow: hidden;
    width: auto;
    margin: auto;
}

.noImgContainer {
    overflow: hidden;
    margin-bottom: 8px;
}

.noImgContainer img {
    display: block;
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.titleColumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 5;
}

.iconColumn {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
}

.centerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.titleAndIconSectionWrapper {
    display: flex;
}

.titleSectionWrapper {
    margin-right: 16px;
}

.favoriteIcon {
    margin-left: auto;
    padding-top: 4px;
    height: 24px;
    width: 24px;
}

.link {
    display: inline-block;
    color: inherit;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.chevronIcon {
    margin-left: 2px;
    display: inline-block;
    height: 10px;
    width: 10px;
}

/**
*** Extra SMALL ***
*/
@media screen and (min-width: 350px) {
    .sixteenByNine .imgContainer {
        width: 350px;
    }

    .sixteenByNine .container {
        width: 350px;
        margin: 40px auto 64px;
        padding: 0;
    }
}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
    .container {
        padding: 56px 165px 64px;
    }

    h4.header {
        margin-bottom: 24px;
        font-size: 36px;
        line-height: 44px;
    }

    .sixteenByNine .imgContainer {
        width: 480px;
    }

    .sixteenByNine .container {
        width: 480px;
        margin: 56px auto 64px;
    }
}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
    .container {
        padding: 64px 220px;
    }

    .sixteenByNine .imgContainer {
        width: 960px;
        height: 540px;
    }

    .sixteenByNine .container {
        width: 960px;
        margin: 64px auto;
    }

    .nineBySixteen .container {
        padding: 64px 112px;
    }

    h4.header {
        font-size: 45px;
        line-height: 56px;
    }

    .nineBySixteen .titleSection {
        width: auto;
    }

    p.heading {
        font-size: 22px;
        line-height: 28px;
    }

    .subtitleSection {
        padding-top: 2px;
    }

    .imgContainer {
        margin-bottom: 18px;
    }

    .nineBySixteen .imgContainer {
        margin: 0 80px 0 0;
        width: 455px;
    }

    .nineBySixteen .containerWrapper {
        padding-top: 152px;
    }

    .nineBySixteen .iconSection {
        width: 32px;
        margin-bottom: 16px;
        cursor: pointer;
    }

    .nineBySixteen .title {
        padding: 0;
    }

    .nineBySixteen .subtitleSection {
        padding-top: 4px;
        margin-bottom: 8px;
    }

    .nineBySixteenSmallContainer {
        display: none;
    }

    .nineBySixteenLargeContainer {
        display: flex;
    }

    .nineBySixteen .favoriteIcon {
        padding-top: 0;
        margin-left: 0;
    }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
    .container {
        padding: 64px 320px;
    }

    .nineBySixteen .container {
        padding: 64px 200px;
    }

    .nineBySixteen .imgContainer {
        margin: 0 92px 0 0;
    }

    .nineBySixteen .containerWrapper {
        padding-top: 161px;
    }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
    .container {
        padding: 64px 448px;
    }

    .nineBySixteen .container {
        padding: 64px 204px;
    }

    .nineBySixteen .imgContainer {
        margin: 0 120px 0 0;
    }

    .nineBySixteen .containerWrapper {
        padding-top: 191px;
    }

    .nineBySixteen .detailsSection {
        width: 648px;
    }
}

@media screen and (min-width: 1920px) {
    .contentBody {
        height: 90vh;
        display: flex;
    }

    .sixteenByNine .contentBody {
        align-items: center;
        justify-content: center;
    }

    .nineBySixteen .contentBody {
        align-items: center;
    }
}