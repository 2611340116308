h2.titleContainer {
    padding: 34px 16px 0;
}

p.title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
}

div.contentWrapper {
    padding: 0 16px;
}

p.content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 16px;
}

div.btnWrapper {
    padding: 24px 0 34px;
    justify-content: center;
}

button.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 2px solid #CBCDDC;
    border-radius: 4px;
    font-weight: 600;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
    h2.titleContainer {
        padding: 40px 104px 0;
    }

    div.btnWrapper {
        padding: 32px 0 40px;
    }
}