div.dialogPaper {
    width: 504px;
    height: 236px;
}

div.btnWrapper {
    padding: 14px 0 24px;
    justify-content: center;
}

button.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 2px solid #CBCDDC;
    border-radius: 4px;
    font-weight: 600;
}

div.contentText {
    padding-top: 16px;
}


/**
*** Extra SMALL ***
**/
@media screen and (max-width: 320px) {
    div.dialogPaper {
        height: 266px;
    }
}

/**
*** SMALL ***
**/
@media screen and (min-width: 400px) {
    div.dialogPaper {
        width: 328px;
        height: 244px;
    }

    div.contentText {
        padding-top: 16px;
    }

    div.btnWrapper {
        padding: 14px 0 24px;
        justify-content: center;
    }
}

@media screen and (min-width: 500px) {
    div.dialogPaper {
        width: 504px;
        height: 236px;
    }

    div.btnWrapper {
        padding: 14px 0 34px;
        justify-content: center;
    }
}