.swiper.meetTheArtistSwiper {
  position: static;
}

.animationSwiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: #71797E;
  margin: 0 9px 40px;
  height: 7px;
  width: 7px;
}

.animationSwiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #E5E4E2;
}

.loadingSwiper {
  color: #585DE9;
}

/**
  *** SMALL ***
  **/
@media screen and (min-width: 600px) {
  .animationSwiper .swiper-button-prev {
    left: 30px;
  }

  .animationSwiper .swiper-button-next {
    right: 30px;
  }
}

/**
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .animationSwiper .swiper-button-prev {
    left: 40px;
  }

  .animationSwiper .swiper-button-next {
    right: 40px;
  }
}

/**
    *** LARGE ***
    **/
@media screen and (min-width: 1200px) {
  .animationSwiper .swiper-button-prev {
    left: 50px;
  }

  .animationSwiper .swiper-button-next {
    right: 50px;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1920px) {
  .animationSwiper .swiper-button-prev {
    left: 60px;
  }

  .animationSwiper .swiper-button-next {
    right: 60px;
  }
}