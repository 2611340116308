* {
  box-sizing: border-box;
  font-family: SamsungOne, sans-serif !important;
}
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: SamsungOne, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Samsung Sharp Sans', sans-serif !important;
  font-weight: 700 !important;
}

@import url('./fonts/fonts.css');
