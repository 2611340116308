.dialogWrapper {
    padding: 16px 16px 36px;
}

.closeBtn {
    text-align: right;
}

p.title {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.iconWrapper {
    margin: 32px 0;
    text-align: center;
}

p.subTitle {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
}

p.subText {
    color: #605D62;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

p.learnMoreText {
    color: var(--primaryColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.title {
        padding: 0 17px;
    }

    p.subTitle {
        padding: 0 11px;
    }
}

@media screen and (min-width: 425px) {
    p.title {
        padding: 0 42px;
    }
}