.container {
  height: 100vh;
  padding: 1rem;
  background-color: #1D1D2F;
  color:#fff;
}

.logo {
  overflow: hidden;
  width: 10%;
  transition: width 0.2s linear;
}

.logo img {
  width: 100%;
  display: block;
}

.header {
  font-weight: 800 !important;
  margin-top: 5rem !important;
  margin-bottom: 1rem !important;
}

.btn {
  display: block;
  color: inherit;
  text-decoration: none;
  border: 1px solid #000;
  padding: 1rem 1.5rem;
}

@media screen and (min-width: 600px) {
  .logo {
    width: 6%;
  }
  .header {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 900px) {
  .logo {
    width: 4%;
  }
  .header {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .logo {
    width: 3%;
  }
  .header {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 1536px) {
  .logo {
    width: 2%;
  }
}
