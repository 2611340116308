/* Colors */
.primaryDarkColor {
  color: #1d1d2f;
}

.primaryColor {
  color: #585de9;
}

/* Background Colors */
.bgPrimaryDarkColor {
  background-color: #1d1d2f;
}

.bgPrimaryColor {
  background-color: #585de9;
}

.bgSecondaryColor {
  background-color: linear-gradient(150deg, #575de8 40%, #8050dd);
  background-image: linear-gradient(150deg, #575de8 40%, #8050dd);
}
