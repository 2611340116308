.dialogWrapper {
    padding: 8px 8px 36px;
}

.container {
    display: flex;
    padding: 16px 16px 36px 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 4px;
    background: linear-gradient(148deg, #575DE8 0%, #8050DD 100%);
}

p.title {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.imgWrapper {
    width: 208px;
    height: 117px;
    flex-shrink: 0;
    border: 4px solid #FFF;
    background: #1D1D2F;
    position: relative;
}

.artStoreIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.checkIcon {
    position: absolute;
    right: 8px;
    bottom: 8px;
    height: 24px;
    width: 24px;
}

p.subTitle {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

p.description {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 30px 0;
}

.btnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btn {
    display: flex;
    width: 112px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 2px solid #C9C5CA;
    background: #FFF;
    cursor: pointer;
}

p.btnText {
    color: var(--primaryColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.title {
        padding: 0 7px;
    }
}