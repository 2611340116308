.swiper.featuredCollectionSwiper {
  position: static;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
  background-color: #fff;
  border: 2px solid #cbcddc;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  font-family: inherit;
  color: var(--primaryColor);
  background-position: center;
  background-repeat: no-repeat;
  transition-property: background-color, background;
  transition-duration: 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.featuredCollectionSwiper .swiper-button-prev,
.featuredCollectionSwiper .swiper-button-next {
  top: 59.5%;
}

.swiper-button-prev {
  background-image: url('/images/carousel_backward.svg');
}

.swiper-button-next {
  background-image: url('/images/carousel_forward.svg');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.swiper-button-next:hover,
.swiper-button-next:focus {
  background: url('/images/carousel_forward.svg'),
    linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev:hover,
.swiper-button-prev:focus {
  background: url('/images/carousel_backward.svg'),
    linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-disabled {
  background-color: #cbcddc;
}

.featuredCollectionSwiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primaryColor);
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .swiper-button-prev {
    left: -20%;
  }

  .swiper-button-next {
    right: -20%;
  }

  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 60%;
  }
}

/** 
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }

  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 59%;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 59.2%;
  }
}

/**
*** EXTRA-Extra-LARGE ***
**/
@media screen and (min-width: 1920px) {
  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 57%;
  }
}