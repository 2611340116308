.swiper.museumsCollectionSwiper {
  position: static;
}

.museumsCollectionSwiper .swiper-button-prev,
.museumsCollectionSwiper .swiper-button-next {
  display: none;
  background-color: #585DE9;
  border: 2px solid #585DE9;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 40.3%;
  transform: translateY(-50%);
  border-radius: 50%;
  font-family: inherit;
  color: var(--primaryColor);
  background-position: center;
  background-repeat: no-repeat;
  transition-property: background-color, background;
  transition-duration: 0.2s;
}

.museumsCollectionSwiper .swiper-button-prev {
  background-image: url('/images/carousel_backward_white.svg');
}

.museumsCollectionSwiper .swiper-button-next {
  background-image: url('/images/carousel_forward_white.svg');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.museumsCollectionSwiper .swiper-button-next:hover,
.museumsCollectionSwiper .swiper-button-next:focus {
  background: url('/images/carousel_forward_white.svg'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #6c70ed;
}

.museumsCollectionSwiper .swiper-button-prev:hover,
.museumsCollectionSwiper .swiper-button-prev:focus {
  background: url('/images/carousel_backward_white.svg'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #6c70ed;
}

.museumsCollectionSwiper .swiper-button-disabled {
  background-color: #585DE9;
}

.museumsCollectionSwiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 0.4;
  background-color: #fff;
}

.museumsCollectionSwiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #fff;
}

/** 
  *** SMALL ***
  **/
@media screen and (min-width: 600px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    display: block;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/** 
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 60%;
  }

  .swiper-button-prev {
    left: -20%;
  }

  .swiper-button-next {
    right: -20%;
  }
}

/** 
    *** LARGE ***
    **/
@media screen and (min-width: 1200px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 59%;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 59.2%;
  }
}

/**
*** EXTRA-Extra-LARGE ***
**/
@media screen and (min-width: 1920px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 57%;
  }
}