.dialogWrapper {
    padding: 16px 16px 48px;
}

.closeBtn {
    text-align: right;
}

p.title {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 10px 0 24px;
}

p.subTitle {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 36px;
}

.btnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btn {
    text-decoration: none;
    display: flex;
    width: 112px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--primaryColor);
    cursor: pointer;
}

p.btnText {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

p.createAccountText {
    color: var(--primaryColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
    cursor: pointer;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.title {
        padding: 0 47px;
    }

    p.subTitle {
        padding: 0 13px;
    }
}