.subscriptionContainer {
  color: #fff;
  padding: 10px 16px 80px;
  text-align: center;
}

h5.heading {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

p.caption {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 24px;
}

.emailField {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 4px;
  border: 1px solid #1d1d2f;
  display: block;
  width: 280px;
  height: 40px;
  outline: none;
  padding-left: 6px;
}

.errorField {
  font-size: 14px !important;
  font-weight: bold !important;
}

.submitBtn {
  display: block;
  margin: 2rem auto 1rem;
  color: var(--primaryColor);
  background-color: #fff;
  border: 2px solid #cbcddc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  width: 110px;
  height: 40px;
  transition: background 0.2s;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.submitBtn:disabled,
.submitBtn:hover,
.submitBtn:focus {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
}

.submitBtn:disabled {
  cursor: not-allowed;
}

.privacyLink {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  margin-top: 1rem;
  text-decoration-thickness: 1px;
  text-decoration-skip-ink: none;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .subscriptionContainer {
    padding: 0 32px 110px;
  }

  h5.heading {
    font-size: 36px;
    line-height: 44px;
  }

  p.caption {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }

  .privacyLink {
    margin-top: 3rem;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .subscriptionContainer {
    padding: 0 112px 128px;
  }

  h5.heading {
    font-size: 36px;
    line-height: 44px;
  }

  p.caption {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .subscriptionContainer {
    padding: 0 244px 128px;
  }

  h5.heading {
    font-size: 36px;
    line-height: 44px;
  }

  p.caption {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }
}

/**
*** EXTRA LARGE ***
**/
@media screen and (min-width: 1536px) {
  .subscriptionContainer {
    padding: 0 64px 128px;
  }

  h5.heading {
    font-size: 36px;
    line-height: 44px;
  }

  p.caption {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }
}
