h2.titleContainer {
    padding: 36px 17px 0;
}

p.title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
}

div.contentWrapper {
    padding: 0 26px;
}

p.content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 16px;
}

div.btnWrapper {
    padding: 32px 44px;
    justify-content: center;
}

.spaceBetween {
    width: 16px;
}

div.btnWrapper button {
    margin-left: 0 !important;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
    h2.titleContainer {
        padding: 36px 27px 0;
    }

    div.contentWrapper {
        padding: 0 40px;
    }

    p.content {
        padding-top: 44px;
    }

    div.btnWrapper {
        padding: 52px 0 32px;
    }

    .spaceBetween {
        width: 25px;
    }
}