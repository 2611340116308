.container {
  background-color: #fff;
  opacity: 0.9;
  padding: 40px 16px;
  color: #000;
}

h5.header {
  text-align: center;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  padding-bottom: 24px;
}

p.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

p.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.subscriptionItem {
  text-align: left;
  margin: 16px 0;
}

.subscriptionIcon {
  margin: 20px 0;
}

.subscriptionContent {
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .container {
    padding: 56px 117px;
  }
  .subscriptionItem {
    margin: 24px 0;
  }
  h5.header {
    font-size: 36px;
    line-height: 44px;
    padding-bottom: 48px;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  p.description {
    font-size: 16px;
    line-height: 24px;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .container {
    padding: 64px 112px;
  }
  .subscriptionItem {
    margin: 24px 0;
  }
  h5.header {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 48px;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  p.description {
    font-size: 16px;
    line-height: 24px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .container {
    padding: 64px 275px;
  }
  .subscriptionItem {
    margin: 24px 0;
  }
  h5.header {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 48px;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  p.description {
    font-size: 16px;
    line-height: 24px;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .container {
    padding: 64px 300px;
  }
  .subscriptionItem {
    margin: 24px 0;
  }
  h5.header {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 48px;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  p.description {
    font-size: 16px;
    line-height: 24px;
  }
}
