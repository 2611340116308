@font-face {
  font-family: SamsungOne;
  font-weight: 200;
  src: local('SamsungOne'), url('./SamsungOneUI-200.woff');
}

@font-face {
  font-family: SamsungOne;
  font-weight: 300;
  src: local('SamsungOne'), url('./SamsungOneUI-300.woff');
}

@font-face {
  font-family: SamsungOne;
  font-weight: 400;
  src: local('SamsungOne'), url('./SamsungOneUI-400.woff');
}

@font-face {
  font-family: SamsungOne;
  font-weight: 500;
  src: local('SamsungOne'), url('./SamsungOneUI-500.woff');
}

@font-face {
  font-family: SamsungOne;
  font-weight: 600;
  src: local('SamsungOne'), url('./SamsungOneUI-600.woff');
}

@font-face {
  font-family: SamsungOne;
  font-weight: 700;
  src: local('SamsungOne'), url('./SamsungOneUI-700.woff');
}
