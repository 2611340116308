.containerWrapper {
    overflow: hidden;
}

.container {
    position: relative;
    padding: 40px 20px 64px;
    text-align: center;
    overflow: hidden;
}

.subtitleSection {
    text-align: left;
    padding-top: 4px;
}

.title {
    padding: 4px 0;
    text-align: left;
}

.titleInfoSection {
    text-align: center;
    padding-top: 14px;
    font-size: 12px;
    font-weight: 700;
}

/**
*** Extra SMALL ***
*/
@media screen and (min-width: 350px) {
    .sixteenByNine .imgContainer {
        width: 350px;
    }

}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
    .container {
        padding: 56px 165px 64px;
    }


}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
    .container {
        padding: 64px 220px;
    }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
    .container {
        padding: 64px 320px;
    }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
    .container {
        padding: 64px 448px;
    }


}

@media screen and (min-width: 1920px) {
    .contentBody {
        height: 90vh;
        display: flex;
    }
}