@font-face {
  font-family: SamsungOne;
  font-weight: 200;
  src: local('SamsungOne'), url(/static/media/SamsungOneUI-200.2f150a3f.woff);
}

@font-face {
  font-family: SamsungOne;
  font-weight: 300;
  src: local('SamsungOne'), url(/static/media/SamsungOneUI-300.71a3cc92.woff);
}

@font-face {
  font-family: SamsungOne;
  font-weight: 400;
  src: local('SamsungOne'), url(/static/media/SamsungOneUI-400.4b2d7ab1.woff);
}

@font-face {
  font-family: SamsungOne;
  font-weight: 500;
  src: local('SamsungOne'), url(/static/media/SamsungOneUI-500.f0a0965b.woff);
}

@font-face {
  font-family: SamsungOne;
  font-weight: 600;
  src: local('SamsungOne'), url(/static/media/SamsungOneUI-600.22397bc7.woff);
}

@font-face {
  font-family: SamsungOne;
  font-weight: 700;
  src: local('SamsungOne'), url(/static/media/SamsungOneUI-700.92da78eb.woff);
}

@font-face {
  font-family: 'Samsung Sharp Sans';
  font-weight: bold;
  src: local('SamsungOne'), url(/static/media/SamsungSharpSans-Bold.4562e0b8.woff) format('woff'),
    url(/static/media/SamsungSharpSans-Bold.93cd7ef4.woff2) format('woff2'),
    url(/static/media/SamsungSharpSans-Bold.81bac442.eot) format('eot'),
    url(/static/media/SamsungSharpSans-Bold.b1b69b27.svg) format('svg');
}



* {
  box-sizing: border-box;
  font-family: SamsungOne, sans-serif !important;
}
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: SamsungOne, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Samsung Sharp Sans', sans-serif !important;
  font-weight: 700 !important;
}

:root {
  --primaryColor: #585de9;
  --primaryDarkColor: #1d1d2f;
  --secondaryColor: linear-gradient(100deg, #575de8 20%, #8050dd);
}

/* Background Colors */

.LandingPage_banner__3F1py {
  background-color: #1d1d2f;
  background-color: var(--primaryDarkColor);
  color: #fff;
}

.LandingPage_bannerLogo__3nWS2 {
  background-color: #1d1d2f;
  background-color: var(--primaryDarkColor);
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid #ffffff;
}

.LandingPage_artStoreBannerLogo__2leyt {
  margin-top: 48px;
  height: 24px;
}

h2.LandingPage_bannerHeadline__14Nho {
  font-style: normal;
  color: #ffffff;
  font-size: 28px;
  line-height: 36px;
  padding: 0 20px;
  margin-top: 36px;
}
h2.LandingPage_bannerHeadline__14Nho span {
  display: block;
  font-family: 'Samsung Sharp Sans', sans-serif !important;
}

p.LandingPage_bannerSubHeading__3WYmn {
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  padding: 0 40px;
  margin-bottom: 48px;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .LandingPage_artStoreBannerLogo__2leyt {
    margin-top: 56px;
  }
  h2.LandingPage_bannerHeadline__14Nho {
    font-size: 56px;
    line-height: 64px;
    padding: 0 10px;
  }
  p.LandingPage_bannerSubHeading__3WYmn {
    font-size: 16px;
    line-height: 24px;
    margin-top: 36px;
    padding: 0 100px;
  }
  p.LandingPage_bannerCaption__10fpt {
    font-size: 16px;
    line-height: 24px;
    margin-top: 36px;
    padding: 0 127px;
    margin-bottom: 56px;
  }
  .LandingPage_btn__2QDKC {
    width: 20%;
  }
  .LandingPage_footer__HFccZ {
    padding: 4rem;
  }
  .LandingPage_subscriptionContainer__10bRJ {
    padding: 4rem;
  }

  .LandingPage_carouselCaption__1SXzZ {
    width: 50%;
    margin-top: 1rem !important;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .LandingPage_artStoreBannerLogo__2leyt {
    margin-top: 78px;
    height: 36px;
  }
  h2.LandingPage_bannerHeadline__14Nho {
    padding: 0 112px;
    margin-top: 48px;
  }
  p.LandingPage_bannerSubHeading__3WYmn {
    margin-top: 48px;
    padding: 0;
  }
  p.LandingPage_bannerSubHeading__3WYmn span {
    display: block;
  }
  p.LandingPage_bannerCaption__10fpt {
    font-size: 22px;
    line-height: 28px;
    margin-top: 48px;
    padding: 0 112px;
    margin-bottom: 78px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  h2.LandingPage_bannerHeadline__14Nho {
    padding: 0 275px;
  }
  p.LandingPage_bannerSubHeading__3WYmn {
    margin-top: 48px;
  }
  p.LandingPage_bannerCaption__10fpt {
    font-size: 22px;
    line-height: 28px;
    margin-top: 48px;
    padding: 0 275px;
    margin-bottom: 78px;
  }
}

/**
*** EXTRA LARGE ***
**/
@media screen and (min-width: 1536px) {
  h2.LandingPage_bannerHeadline__14Nho {
    padding: 0 300px;
  }
  p.LandingPage_bannerSubHeading__3WYmn {
    margin-top: 48px;
    padding: 0 300px;
  }
  p.LandingPage_bannerCaption__10fpt {
    font-size: 22px;
    line-height: 28px;
    margin-top: 48px;
    padding: 0 300px;
    margin-bottom: 78px;
  }
}

.HowToSubscribe_container__2nquu {
  background-color: #fff;
  opacity: 0.9;
  padding: 40px 16px;
  color: #000;
}

h5.HowToSubscribe_header__1_nM1 {
  text-align: center;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  padding-bottom: 24px;
}

p.HowToSubscribe_heading__2O93o {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

p.HowToSubscribe_description__3P5qw {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.HowToSubscribe_subscriptionItem__SHKF9 {
  text-align: left;
  margin: 16px 0;
}

.HowToSubscribe_subscriptionIcon__myWP6 {
  margin: 20px 0;
}

.HowToSubscribe_subscriptionContent__1dqVU {
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .HowToSubscribe_container__2nquu {
    padding: 56px 117px;
  }
  .HowToSubscribe_subscriptionItem__SHKF9 {
    margin: 24px 0;
  }
  h5.HowToSubscribe_header__1_nM1 {
    font-size: 36px;
    line-height: 44px;
    padding-bottom: 48px;
  }
  p.HowToSubscribe_heading__2O93o {
    font-size: 22px;
    line-height: 28px;
  }
  p.HowToSubscribe_description__3P5qw {
    font-size: 16px;
    line-height: 24px;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .HowToSubscribe_container__2nquu {
    padding: 64px 112px;
  }
  .HowToSubscribe_subscriptionItem__SHKF9 {
    margin: 24px 0;
  }
  h5.HowToSubscribe_header__1_nM1 {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 48px;
  }
  p.HowToSubscribe_heading__2O93o {
    font-size: 22px;
    line-height: 28px;
  }
  p.HowToSubscribe_description__3P5qw {
    font-size: 16px;
    line-height: 24px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .HowToSubscribe_container__2nquu {
    padding: 64px 275px;
  }
  .HowToSubscribe_subscriptionItem__SHKF9 {
    margin: 24px 0;
  }
  h5.HowToSubscribe_header__1_nM1 {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 48px;
  }
  p.HowToSubscribe_heading__2O93o {
    font-size: 22px;
    line-height: 28px;
  }
  p.HowToSubscribe_description__3P5qw {
    font-size: 16px;
    line-height: 24px;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .HowToSubscribe_container__2nquu {
    padding: 64px 300px;
  }
  .HowToSubscribe_subscriptionItem__SHKF9 {
    margin: 24px 0;
  }
  h5.HowToSubscribe_header__1_nM1 {
    font-size: 45px;
    line-height: 56px;
    padding-bottom: 48px;
  }
  p.HowToSubscribe_heading__2O93o {
    font-size: 22px;
    line-height: 28px;
  }
  p.HowToSubscribe_description__3P5qw {
    font-size: 16px;
    line-height: 24px;
  }
}

h2.PushNotificationHandRaiser_titleContainer__1_Lb9 {
    padding: 36px 17px 0;
}

p.PushNotificationHandRaiser_title__QGCoC {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
}

div.PushNotificationHandRaiser_contentWrapper__tpUnn {
    padding: 0 26px;
}

p.PushNotificationHandRaiser_content__3QUNT {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 16px;
}

div.PushNotificationHandRaiser_btnWrapper__2CqD- {
    padding: 32px 44px;
    justify-content: center;
}

.PushNotificationHandRaiser_spaceBetween__329eL {
    width: 16px;
}

div.PushNotificationHandRaiser_btnWrapper__2CqD- button {
    margin-left: 0 !important;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
    h2.PushNotificationHandRaiser_titleContainer__1_Lb9 {
        padding: 36px 27px 0;
    }

    div.PushNotificationHandRaiser_contentWrapper__tpUnn {
        padding: 0 40px;
    }

    p.PushNotificationHandRaiser_content__3QUNT {
        padding-top: 44px;
    }

    div.PushNotificationHandRaiser_btnWrapper__2CqD- {
        padding: 52px 0 32px;
    }

    .PushNotificationHandRaiser_spaceBetween__329eL {
        width: 25px;
    }
}
div.IosEmailConfirmationHandRaiser_dialogPaper__1Y530 {
    width: 504px;
    height: 236px;
}

div.IosEmailConfirmationHandRaiser_btnWrapper__1qFHD {
    padding: 14px 0 24px;
    justify-content: center;
}

button.IosEmailConfirmationHandRaiser_btn__2cl0J {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    grid-gap: 10px;
    gap: 10px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 2px solid #CBCDDC;
    border-radius: 4px;
    font-weight: 600;
}

div.IosEmailConfirmationHandRaiser_contentText__pvrLW {
    padding-top: 16px;
}


/**
*** Extra SMALL ***
**/
@media screen and (max-width: 320px) {
    div.IosEmailConfirmationHandRaiser_dialogPaper__1Y530 {
        height: 266px;
    }
}

/**
*** SMALL ***
**/
@media screen and (min-width: 400px) {
    div.IosEmailConfirmationHandRaiser_dialogPaper__1Y530 {
        width: 328px;
        height: 244px;
    }

    div.IosEmailConfirmationHandRaiser_contentText__pvrLW {
        padding-top: 16px;
    }

    div.IosEmailConfirmationHandRaiser_btnWrapper__1qFHD {
        padding: 14px 0 24px;
        justify-content: center;
    }
}

@media screen and (min-width: 500px) {
    div.IosEmailConfirmationHandRaiser_dialogPaper__1Y530 {
        width: 504px;
        height: 236px;
    }

    div.IosEmailConfirmationHandRaiser_btnWrapper__1qFHD {
        padding: 14px 0 34px;
        justify-content: center;
    }
}
h2.EmailConfirmationHandRaiser_titleContainer__2c6zY {
    padding: 34px 16px 0;
}

p.EmailConfirmationHandRaiser_title__9J8DU {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
}

div.EmailConfirmationHandRaiser_contentWrapper__1Gh5I {
    padding: 0 16px;
}

p.EmailConfirmationHandRaiser_content__2Yddq {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding-top: 16px;
}

div.EmailConfirmationHandRaiser_btnWrapper__13rLx {
    padding: 24px 0 34px;
    justify-content: center;
}

button.EmailConfirmationHandRaiser_btn__1sAC7 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    grid-gap: 10px;
    gap: 10px;
    width: 100px;
    height: 40px;
    background: #FFFFFF;
    border: 2px solid #CBCDDC;
    border-radius: 4px;
    font-weight: 600;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
    h2.EmailConfirmationHandRaiser_titleContainer__2c6zY {
        padding: 40px 104px 0;
    }

    div.EmailConfirmationHandRaiser_btnWrapper__13rLx {
        padding: 32px 0 40px;
    }
}
.Footer_footer__Aksfi {
  color: white;
  padding: 48px 32px;
}

.Footer_footerLink__3UQlL {
  margin: 0;
  padding: 0;
}

.Footer_footerLink__3UQlL li {
  list-style-type: none;
  padding: 0.25rem 0;
}

.Footer_footerLinkItem__3gAdJ {
  text-decoration: none;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.Footer_footerLinkItem__3gAdJ:hover {
  text-decoration: underline;
}

.Footer_linksHeading__1YIqO {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #cbcddc;
  opacity: 0.7;
  padding-bottom: 8px;
}

p.Footer_footerCopyright__2ev1r,
div.Footer_secondaryNav__2e1tj a.Footer_navLink__y5PH1 {
  -webkit-text-size-adjust: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #9a99b0;
}

p.Footer_footerCopyright__2ev1r {
  margin: 24px 0;
}

p.Footer_footerCopyright__2ev1r a {
  color: inherit;
  text-decoration-thickness: 0.1px;
}

div.Footer_secondaryNav__2e1tj a.Footer_navLink__y5PH1 {
  text-decoration: none;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  margin-right: 20px;
}

div.Footer_secondaryNav__2e1tj a.Footer_navLink__y5PH1:hover {
  text-decoration: underline;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .Footer_footer__Aksfi {
    padding: 48px 64px;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .Footer_footer__Aksfi {
    padding: 48px 112px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .Footer_footer__Aksfi {
    padding: 48px 200px;
  }
}

/**
*** EXTRA LARGE ***
**/
@media screen and (min-width: 1536px) {
  .Footer_footer__Aksfi {
    padding: 48px 204px;
  }
}

/* Colors */
.Color_primaryDarkColor__12P95 {
  color: #1d1d2f;
}

.Color_primaryColor__3h-Sk {
  color: #585de9;
}

/* Background Colors */
.Color_bgPrimaryDarkColor__30-nA {
  background-color: #1d1d2f;
}

.Color_bgPrimaryColor__1ntAb {
  background-color: #585de9;
}

.Color_bgSecondaryColor__24sqT {
  background-color: linear-gradient(150deg, #575de8 40%, #8050dd);
  background-image: linear-gradient(150deg, #575de8 40%, #8050dd);
}

.NewsletterSubscriptionSection_subscriptionContainer__29rHD {
  color: #fff;
  padding: 10px 16px 80px;
  text-align: center;
}

h5.NewsletterSubscriptionSection_heading__3km63 {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

p.NewsletterSubscriptionSection_caption__2OtbX {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 24px;
}

.NewsletterSubscriptionSection_emailField__uGDoJ {
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 4px;
  border: 1px solid #1d1d2f;
  display: block;
  width: 280px;
  height: 40px;
  outline: none;
  padding-left: 6px;
}

.NewsletterSubscriptionSection_errorField__3WJE9 {
  font-size: 14px !important;
  font-weight: bold !important;
}

.NewsletterSubscriptionSection_submitBtn__3pxqi {
  display: block;
  margin: 2rem auto 1rem;
  color: var(--primaryColor);
  background-color: #fff;
  border: 2px solid #cbcddc;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  width: 110px;
  height: 40px;
  transition: background 0.2s;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.NewsletterSubscriptionSection_submitBtn__3pxqi:disabled,
.NewsletterSubscriptionSection_submitBtn__3pxqi:hover,
.NewsletterSubscriptionSection_submitBtn__3pxqi:focus {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
}

.NewsletterSubscriptionSection_submitBtn__3pxqi:disabled {
  cursor: not-allowed;
}

.NewsletterSubscriptionSection_privacyLink__nCsbk {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: inline-block;
  color: #ffffff;
  margin-top: 1rem;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .NewsletterSubscriptionSection_subscriptionContainer__29rHD {
    padding: 0 32px 110px;
  }

  h5.NewsletterSubscriptionSection_heading__3km63 {
    font-size: 36px;
    line-height: 44px;
  }

  p.NewsletterSubscriptionSection_caption__2OtbX {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }

  .NewsletterSubscriptionSection_privacyLink__nCsbk {
    margin-top: 3rem;
  }
}

/**
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .NewsletterSubscriptionSection_subscriptionContainer__29rHD {
    padding: 0 112px 128px;
  }

  h5.NewsletterSubscriptionSection_heading__3km63 {
    font-size: 36px;
    line-height: 44px;
  }

  p.NewsletterSubscriptionSection_caption__2OtbX {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }
}

/**
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .NewsletterSubscriptionSection_subscriptionContainer__29rHD {
    padding: 0 244px 128px;
  }

  h5.NewsletterSubscriptionSection_heading__3km63 {
    font-size: 36px;
    line-height: 44px;
  }

  p.NewsletterSubscriptionSection_caption__2OtbX {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }
}

/**
*** EXTRA LARGE ***
**/
@media screen and (min-width: 1536px) {
  .NewsletterSubscriptionSection_subscriptionContainer__29rHD {
    padding: 0 64px 128px;
  }

  h5.NewsletterSubscriptionSection_heading__3km63 {
    font-size: 36px;
    line-height: 44px;
  }

  p.NewsletterSubscriptionSection_caption__2OtbX {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 48px;
  }
}

.LandingPageAnimation_container__UAXOD {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.swiper.meetTheArtistSwiper {
  position: static;
}

.animationSwiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: #71797E;
  margin: 0 9px 40px;
  height: 7px;
  width: 7px;
}

.animationSwiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #E5E4E2;
}

.loadingSwiper {
  color: #585DE9;
}

/**
  *** SMALL ***
  **/
@media screen and (min-width: 600px) {
  .animationSwiper .swiper-button-prev {
    left: 30px;
  }

  .animationSwiper .swiper-button-next {
    right: 30px;
  }
}

/**
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .animationSwiper .swiper-button-prev {
    left: 40px;
  }

  .animationSwiper .swiper-button-next {
    right: 40px;
  }
}

/**
    *** LARGE ***
    **/
@media screen and (min-width: 1200px) {
  .animationSwiper .swiper-button-prev {
    left: 50px;
  }

  .animationSwiper .swiper-button-next {
    right: 50px;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1920px) {
  .animationSwiper .swiper-button-prev {
    left: 60px;
  }

  .animationSwiper .swiper-button-next {
    right: 60px;
  }
}
.LandingPageFeaturedCollection_containerWrapper__1Nnip {
  padding: 40px 20px;
}

.LandingPageFeaturedCollection_container__2FEbr {
  padding-bottom: 30px;
  text-align: center;
  margin: auto;
  position: relative;
}

.LandingPageFeaturedCollection_featureListcontainer__2jrLx {
  height: 600px;
  width: 100%;
}

p.LandingPageFeaturedCollection_heading__3rFda {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}

p.LandingPageFeaturedCollection_headingWhite__1khzR {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

h4.LandingPageFeaturedCollection_title__3PtJR {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

h4.LandingPageFeaturedCollection_titleWhite__xfVeO {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

h4.LandingPageFeaturedCollection_header__18y3f {
  margin-top: 16px;
  margin-bottom: 16px;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
}

.LandingPageFeaturedCollection_textUpperCase__3BsuB {
  text-transform: uppercase;
}

div.LandingPageFeaturedCollection_carouselContainer__2ViLE {
  height: auto;
  padding-left: 1px;
  padding-right: 1px;
}

.LandingPageFeaturedCollection_carouselContainer__2ViLE {
  padding-top: 8px;
}

.LandingPageFeaturedCollection_imgContainer__XTMkK {
  overflow: hidden;
  padding: 0;
  margin: 24px 0;
  border-radius: 8px;
}

p.LandingPageFeaturedCollection_info__3JRRP {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.LandingPageFeaturedCollection_imgContainer__XTMkK img {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.LandingPageFeaturedCollection_btn__1GMT6 {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #cbcddc;
  border-radius: 4px;
  margin: 30px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}

.LandingPageFeaturedCollection_btnBlue__2zzdw {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: #585DE9;
  border-radius: 4px;
  margin: 24px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}


.LandingPageFeaturedCollection_btn__1GMT6:hover,
.LandingPageFeaturedCollection_btn__1GMT6:focus {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
}

.LandingPageFeaturedCollection_btnBlue__2zzdw:hover,
.LandingPageFeaturedCollection_btnBlue__2zzdw:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
}

.LandingPageFeaturedCollection_btnDisabled__UOxG9 {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
  border: 2px solid #cbcddc;
  border-radius: 4px;
  margin: 30px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}

.LandingPageFeaturedCollection_btnText__2NLj8 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--primaryColor);
  -webkit-user-select: none;
          user-select: none;
}

.LandingPageFeaturedCollection_btnTextWhite__1e1sm {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  -webkit-user-select: none;
          user-select: none;
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .LandingPageFeaturedCollection_container__2FEbr {
    width: 70%;
  }

  .LandingPageFeaturedCollection_containerWrapper__1Nnip {
    padding: 56px 0 54px 0;
  }

  .LandingPageFeaturedCollection_featureListcontainer__2jrLx {
    height: 300px;
    width: 100%;
  }

  p.LandingPageFeaturedCollection_heading__3rFda {
    font-size: 16px;
    line-height: 24px;
  }

  p.LandingPageFeaturedCollection_headingWhite__1khzR {
    font-size: 16px;
    line-height: 24px;
  }

  h4.LandingPageFeaturedCollection_title__3PtJR {
    font-size: 36px;
    line-height: 44px;
  }

  h4.LandingPageFeaturedCollection_titleWhite__xfVeO {
    font-size: 36px;
    line-height: 44px;
  }

  h4.LandingPageFeaturedCollection_header__18y3f {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 44px;
  }

  p.LandingPageFeaturedCollection_info__3JRRP {
    font-size: 16px;
    line-height: 24px;
  }

  .LandingPageFeaturedCollection_imgContainer__XTMkK {
    margin: 36px 0;
    border-radius: 16px;
  }

  .LandingPageFeaturedCollection_btn__1GMT6 {
    margin: 26px auto 0;
  }

  .LandingPageFeaturedCollection_btnDisabled__UOxG9 {
    margin: 26px auto 0;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .LandingPageFeaturedCollection_container__2FEbr {
    width: 60%;
  }

  .LandingPageFeaturedCollection_featureListcontainer__2jrLx {
    height: 300px;
    width: 100%;
  }

  .LandingPageFeaturedCollection_containerWrapper__1Nnip {
    padding: 64px 0 66px 0;
  }

  p.LandingPageFeaturedCollection_heading__3rFda {
    font-size: 22px;
    line-height: 28px;
  }

  p.LandingPageFeaturedCollection_headingWhite__1khzR {
    font-size: 22px;
    line-height: 28px;
  }

  h4.LandingPageFeaturedCollection_title__3PtJR {
    font-size: 45px;
    line-height: 56px;
  }

  h4.LandingPageFeaturedCollection_titleWhite__xfVeO {
    font-size: 45px;
    line-height: 56px;
  }

  h4.LandingPageFeaturedCollection_header__18y3f {
    font-size: 45px;
    line-height: 56px;
  }

  p.LandingPageFeaturedCollection_info__3JRRP {
    font-size: 16px;
    line-height: 24px;
  }

  .LandingPageFeaturedCollection_imgContainer__XTMkK {
    margin: 48px 0;
    border-radius: 16px;
  }

  .LandingPageFeaturedCollection_carouselContainer__2ViLE {
    padding-top: 16px;
  }

  .LandingPageFeaturedCollection_btn__1GMT6 {
    margin: 38px auto 0;
  }

  .LandingPageFeaturedCollection_btnDisabled__UOxG9 {
    margin: 38px auto 0;
  }
}

/** 
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .LandingPageFeaturedCollection_container__2FEbr {
    width: 50%;
  }

  .LandingPageFeaturedCollection_featureListcontainer__2jrLx {
    height: 600px;
    width: 100%;
  }

  .LandingPageFeaturedCollection_containerWrapper__1Nnip {
    padding: 64px 0 64px 0;
  }

  p.LandingPageFeaturedCollection_heading__3rFda {
    font-size: 22px;
    line-height: 28px;
  }

  p.LandingPageFeaturedCollection_headingWhite__1khzR {
    font-size: 22px;
    line-height: 28px;
  }

  h4.LandingPageFeaturedCollection_title__3PtJR {
    font-size: 45px;
    line-height: 56px;
  }

  h4.LandingPageFeaturedCollection_titleWhite__xfVeO {
    font-size: 45px;
    line-height: 56px;
  }

  p.LandingPageFeaturedCollection_info__3JRRP {
    font-size: 16px;
    line-height: 24px;
  }

  .LandingPageFeaturedCollection_imgContainer__XTMkK {
    margin: 48px 0;
    border-radius: 16px;
  }

  .LandingPageFeaturedCollection_carouselContainer__2ViLE {
    padding-top: 16px;
  }

  .LandingPageFeaturedCollection_btn__1GMT6 {
    margin: 38px auto 0;
  }

  .LandingPageFeaturedCollection_btnDisabled__UOxG9 {
    margin: 38px auto 0;
  }
}

/** 
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .LandingPageFeaturedCollection_container__2FEbr {
    width: 40%;
  }

  .LandingPageFeaturedCollection_featureListcontainer__2jrLx {
    height: 600px;
    width: 100%;
  }

  .LandingPageFeaturedCollection_containerWrapper__1Nnip {
    padding: 64px 0 64px 0;
  }

  p.LandingPageFeaturedCollection_heading__3rFda {
    font-size: 22px;
    line-height: 28px;
  }

  p.LandingPageFeaturedCollection_headingWhite__1khzR {
    font-size: 22px;
    line-height: 28px;
  }

  h4.LandingPageFeaturedCollection_title__3PtJR {
    font-size: 45px;
    line-height: 56px;
  }

  h4.LandingPageFeaturedCollection_titleWhite__xfVeO {
    font-size: 45px;
    line-height: 56px;
  }

  p.LandingPageFeaturedCollection_info__3JRRP {
    font-size: 16px;
    line-height: 24px;
  }

  .LandingPageFeaturedCollection_imgContainer__XTMkK {
    margin: 48px 0;
    border-radius: 16px;
  }

  .LandingPageFeaturedCollection_carouselContainer__2ViLE {
    padding-top: 16px;
  }

  .LandingPageFeaturedCollection_btn__1GMT6 {
    margin: 38px auto 0;
  }

  .LandingPageFeaturedCollection_btnDisabled__UOxG9 {
    margin: 38px auto 0;
  }
}
.swiper.featuredCollectionSwiper {
  position: static;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
  background-color: #fff;
  border: 2px solid #cbcddc;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  font-family: inherit;
  color: var(--primaryColor);
  background-position: center;
  background-repeat: no-repeat;
  transition-property: background-color, background;
  transition-duration: 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.featuredCollectionSwiper .swiper-button-prev,
.featuredCollectionSwiper .swiper-button-next {
  top: 59.5%;
}

.swiper-button-prev {
  background-image: url('/images/carousel_backward.svg');
}

.swiper-button-next {
  background-image: url('/images/carousel_forward.svg');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.swiper-button-next:hover,
.swiper-button-next:focus {
  background: url('/images/carousel_forward.svg'),
    linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev:hover,
.swiper-button-prev:focus {
  background: url('/images/carousel_backward.svg'),
    linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-disabled {
  background-color: #cbcddc;
}

.featuredCollectionSwiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primaryColor);
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .swiper-button-prev {
    left: -20%;
  }

  .swiper-button-next {
    right: -20%;
  }

  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 60%;
  }
}

/** 
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }

  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 59%;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 59.2%;
  }
}

/**
*** EXTRA-Extra-LARGE ***
**/
@media screen and (min-width: 1920px) {
  .featuredCollectionSwiper .swiper-button-prev,
  .featuredCollectionSwiper .swiper-button-next {
    top: 57%;
  }
}
.swiper.museumsCollectionSwiper {
  position: static;
}

.museumsCollectionSwiper .swiper-button-prev,
.museumsCollectionSwiper .swiper-button-next {
  display: none;
  background-color: #585DE9;
  border: 2px solid #585DE9;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 40.3%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  font-family: inherit;
  color: var(--primaryColor);
  background-position: center;
  background-repeat: no-repeat;
  transition-property: background-color, background;
  transition-duration: 0.2s;
}

.museumsCollectionSwiper .swiper-button-prev {
  background-image: url('/images/carousel_backward_white.svg');
}

.museumsCollectionSwiper .swiper-button-next {
  background-image: url('/images/carousel_forward_white.svg');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.museumsCollectionSwiper .swiper-button-next:hover,
.museumsCollectionSwiper .swiper-button-next:focus {
  background: url('/images/carousel_forward_white.svg'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #6c70ed;
}

.museumsCollectionSwiper .swiper-button-prev:hover,
.museumsCollectionSwiper .swiper-button-prev:focus {
  background: url('/images/carousel_backward_white.svg'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #6c70ed;
}

.museumsCollectionSwiper .swiper-button-disabled {
  background-color: #585DE9;
}

.museumsCollectionSwiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 0.4;
  background-color: #fff;
}

.museumsCollectionSwiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #fff;
}

/** 
  *** SMALL ***
  **/
@media screen and (min-width: 600px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    display: block;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/** 
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 60%;
  }

  .swiper-button-prev {
    left: -20%;
  }

  .swiper-button-next {
    right: -20%;
  }
}

/** 
    *** LARGE ***
    **/
@media screen and (min-width: 1200px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 59%;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 59.2%;
  }
}

/**
*** EXTRA-Extra-LARGE ***
**/
@media screen and (min-width: 1920px) {

  .museumsCollectionSwiper .swiper-button-prev,
  .museumsCollectionSwiper .swiper-button-next {
    top: 57%;
  }
}
.swiper.contemporaryArtistsSwiper {
  position: static;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
  background-color: #fff;
  border: 2px solid #cbcddc;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  font-family: inherit;
  color: var(--primaryColor);
  background-position: center;
  background-repeat: no-repeat;
  transition-property: background-color, background;
  transition-duration: 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.contemporaryArtistsSwiper .swiper-button-prev,
.contemporaryArtistsSwiper .swiper-button-next {
  top: 59.5%;
}

.swiper-button-prev {
  background-image: url('/images/carousel_backward.svg');
}

.swiper-button-next {
  background-image: url('/images/carousel_forward.svg');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.swiper-button-next:hover,
.swiper-button-next:focus {
  background: url('/images/carousel_forward.svg'),
    linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev:hover,
.swiper-button-prev:focus {
  background: url('/images/carousel_backward.svg'),
    linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #fff;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-disabled {
  background-color: #cbcddc;
}

.contemporaryArtistsSwiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--primaryColor);
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .swiper-button-prev {
    left: -20%;
  }

  .swiper-button-next {
    right: -20%;
  }

  .contemporaryArtistsSwiper .swiper-button-prev,
  .contemporaryArtistsSwiper .swiper-button-next {
    top: 60%;
  }
}

/** 
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }

  .contemporaryArtistsSwiper .swiper-button-prev,
  .contemporaryArtistsSwiper .swiper-button-next {
    top: 59%;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {

  .contemporaryArtistsSwiper .swiper-button-prev,
  .contemporaryArtistsSwiper .swiper-button-next {
    top: 59.2%;
  }
}

/**
*** EXTRA-Extra-LARGE ***
**/
@media screen and (min-width: 1920px) {

  .contemporaryArtistsSwiper .swiper-button-prev,
  .contemporaryArtistsSwiper .swiper-button-next {
    top: 57%;
  }
}
.logo_Logo__3P1jt {
  text-align: center;
  padding: 14px 0;
  flex-grow: 1;
  background-color: var(--primaryDarkColor);
}

.logo_profileIcon__1-CQR {
  margin: 12px 16px 12px 0;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.logo_HeaderBorderBottom__38m6C {
  border-bottom: 1px solid #3e3e52;
}

.logo_BackArrow__266I- {
  background-color: var(--primaryDarkColor);
}

.logo_BackArrow__266I- img {
  padding: 16px;
  padding-left: 24px;
  cursor: pointer;
}

.logo_ProfileIcon__3enim {
  background-color: var(--primaryDarkColor);
}

.logo_ProfileIcon__3enim img {
  margin-right: 25px;
  width: 15px;
  height: 18px;
  cursor: pointer;
}

.logo_ProfileAvatar__2s8Fd {
  margin-right: 25px;
  width: 15px;
  height: 18px;
  cursor: pointer;
}

.logo_LeftSpace__1OnZZ {
  padding: 25px
}

.logo_LoginAccount__3nc_7 {
  background-color: var(--primaryDarkColor);
  align-items: left;
}

.logo_LoginAccount__3nc_7 img {
  padding: 16px;
  padding-left: 24px;
  cursor: pointer;
}

.logo_anchorElement__2fcUP {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.logo_anchorContainerFlex__3zlT4 {
  display: flex;
  align-items: center;
  background-color: var(--primaryDarkColor);
}

.logo_anchorContainerGrid__37iR7 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.logo_loginLinkItem__XUma4 {
  text-decoration: none;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryDarkColor);
}

.logo_loginLinkItem__XUma4:hover {
  text-decoration: underline;
}
.login\.dialog_dialogWrapper__3I4tK {
    padding: 16px 16px 48px;
}

.login\.dialog_closeBtn__DzqEA {
    text-align: right;
}

p.login\.dialog_title__Xqidr {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 10px 0 24px;
}

p.login\.dialog_subTitle__22QDt {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 36px;
}

.login\.dialog_btnWrapper__IXBqB {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login\.dialog_btn__2W9p4 {
    text-decoration: none;
    display: flex;
    width: 112px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 4px;
    background: var(--primaryColor);
    cursor: pointer;
}

p.login\.dialog_btnText__1w4uv {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

p.login\.dialog_createAccountText__1Fo7Z {
    color: var(--primaryColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 24px;
    cursor: pointer;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.login\.dialog_title__Xqidr {
        padding: 0 47px;
    }

    p.login\.dialog_subTitle__22QDt {
        padding: 0 13px;
    }
}
.login\.confirmation\.dialog_dialogWrapper__Oyzjy {
    padding: 8px 8px 36px;
}

.login\.confirmation\.dialog_container__e1fDg {
    display: flex;
    padding: 16px 16px 36px 16px;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    align-self: stretch;
    border-radius: 4px;
    background: linear-gradient(148deg, #575DE8 0%, #8050DD 100%);
}

p.login\.confirmation\.dialog_title__3PJXa {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.login\.confirmation\.dialog_imgWrapper__31kBB {
    width: 208px;
    height: 117px;
    flex-shrink: 0;
    border: 4px solid #FFF;
    background: #1D1D2F;
    position: relative;
}

.login\.confirmation\.dialog_artStoreIcon__1Vp1W {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login\.confirmation\.dialog_checkIcon__36ZCo {
    position: absolute;
    right: 8px;
    bottom: 8px;
    height: 24px;
    width: 24px;
}

p.login\.confirmation\.dialog_subTitle__22B2M {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

p.login\.confirmation\.dialog_description__kh1JB {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 30px 0;
}

.login\.confirmation\.dialog_btnWrapper__2vleS {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login\.confirmation\.dialog_btn__T8pO_ {
    display: flex;
    width: 112px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 4px;
    border: 2px solid #C9C5CA;
    background: #FFF;
    cursor: pointer;
}

p.login\.confirmation\.dialog_btnText__srqeK {
    color: var(--primaryColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.login\.confirmation\.dialog_title__3PJXa {
        padding: 0 7px;
    }
}
.login\.error\.dialog_dialogWrapper__DMZkR {
    padding: 16px 16px 36px;
}

.login\.error\.dialog_closeBtn__25P9e {
    text-align: right;
}

p.login\.error\.dialog_title__3Qlhk {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.login\.error\.dialog_iconWrapper__2Tb6H {
    margin: 32px 0;
    text-align: center;
}

p.login\.error\.dialog_subTitle__3l6vs {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
}

p.login\.error\.dialog_subText__3mYem {
    color: #605D62;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

p.login\.error\.dialog_learnMoreText__2_5om {
    color: var(--primaryColor);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.login\.error\.dialog_title__3Qlhk {
        padding: 0 17px;
    }

    p.login\.error\.dialog_subTitle__3l6vs {
        padding: 0 11px;
    }
}

@media screen and (min-width: 425px) {
    p.login\.error\.dialog_title__3Qlhk {
        padding: 0 42px;
    }
}
.profile\.dialog_dialogWrapper__3KktV {
    padding: 16px 16px 40px 16px;
}

.profile\.dialog_closeBtn__23NOv {
    text-align: right;
}

.profile\.dialog_contentWrapper__3XEPV {
    margin: 0 8px;
}

.profile\.dialog_avatarWrapper__3BjeR {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px 0;
}

p.profile\.dialog_name__an45f {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

p.profile\.dialog_email__1JIPk {
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
}

.profile\.dialog_memberWrapper__1jd7v {
    text-align: left;
    border-top: 1px solid #C9C5CA;
    padding-top: 24px;
    margin-top: 24px;
}

.profile\.dialog_noneMemberWrapper__ysr_r {
    width: 110px;
}

p.profile\.dialog_memberTitle__2Q9Ry {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

p.profile\.dialog_memberDescription__1Zv6Y {
    color: #939094;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

p.profile\.dialog_joinBtnText__2NMJX {
    color: #585DE9;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

p.profile\.dialog_joinBtnText__2NMJX:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

p.profile\.dialog_logoutBtnText__1UDek {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-top: 20px;
    cursor: pointer;
    display: inline-block;
}

p.profile\.dialog_logoutBtnText__1UDek:hover {
    text-decoration: underline;
}

.profile\.dialog_subscibeStatusChip__1gys5 {
    font-weight: bold;
    font-size: 12px;
}

/**
*** Extra SMALL ***
**/
@media screen and (min-width: 321px) {
    p.profile\.dialog_title__2zW6T {
        padding: 0 47px;
    }

    p.profile\.dialog_subTitle__34mK1 {
        padding: 0 13px;
    }
}
.LandingPageFrameSection_frameContainer__2mykc {
  padding: 40px 34px;
  text-align: center;
}

.LandingPageFrameSection_frameBody__1Wmcm {
  border-radius: 8px;
  overflow: hidden;
}

.LandingPageFrameSection_frameImageContainer__2vu1d {
  height: 200px;
}

.LandingPageFrameSection_frameImageContainer__2vu1d section {
  height: 100%;
  width: 100%;
}
.LandingPageFrameSection_frameImage__2Ik8U {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 3 / 2;
}

.LandingPageFrameSection_frameContent__3o6ci {
  background-color: #fff;
  padding: 32px 24px;
}

h5.LandingPageFrameSection_frameContentHeading__1Rvlr {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

p.LandingPageFrameSection_frameContentCaption__35hvO {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-top: 8px;
}

.LandingPageFrameSection_btnWrapper__E1j02 {
  background-color: var(--primaryColor);
  margin: 32px auto auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  grid-gap: 10px;
  gap: 10px;
  width: 102px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.LandingPageFrameSection_btnWrapper__E1j02:hover,
.LandingPageFrameSection_btnWrapper__E1j02:focus {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    #585de9;
}

.LandingPageFrameSection_btnText__1lKSh {
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
}

/**
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .LandingPageFrameSection_frameContainer__2mykc {
    padding: 56px 32px;
  }
  .LandingPageFrameSection_frameBody__1Wmcm {
    height: 250px;
    border-radius: 16px;
  }
  .LandingPageFrameSection_frameImageContainer__2vu1d {
    height: 250px;
  }
  .LandingPageFrameSection_frameContent__3o6ci {
    text-align: left;
    padding: 33px 32px;
  }
  h5.LandingPageFrameSection_frameContentHeading__1Rvlr {
    font-size: 26px;
    text-align: left;
  }
  p.LandingPageFrameSection_frameContentCaption__35hvO {
    text-align: left;
  }
  .LandingPageFrameSection_btnWrapper__E1j02 {
    margin: 34px 0 0;
  }
}

/**
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .LandingPageFrameSection_frameContainer__2mykc {
    padding: 64px 112px;
  }
  .LandingPageFrameSection_frameBody__1Wmcm {
    height: 280px;
  }

  .LandingPageFrameSection_frameImageContainer__2vu1d {
    height: 280px;
  }
  .LandingPageFrameSection_frameContent__3o6ci {
    padding: 36px 32px;
  }
  h5.LandingPageFrameSection_frameContentHeading__1Rvlr {
    font-size: 28px;
  }
  p.LandingPageFrameSection_frameContentCaption__35hvO {
    font-size: 16px;
    line-height: 24px;
  }
  .LandingPageFrameSection_btnWrapper__E1j02 {
    margin: 24px 0 0;
  }
}

/**
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .LandingPageFrameSection_frameContainer__2mykc {
    padding: 64px 212px;
  }
  .LandingPageFrameSection_frameBody__1Wmcm {
    width: 856px;
    margin-left: auto;
    margin-right: auto;
  }
}

/**
  *** EXTRA LARGE ***
  **/
@media screen and (min-width: 1536px) {
  .LandingPageFrameSection_frameContainer__2mykc {
    padding: 64px 339px;
  }
  .LandingPageFrameSection_frameBody__1Wmcm {
    height: 320px;
    border-radius: 12px;
  }
  .LandingPageFrameSection_frameImageContainer__2vu1d {
    height: 320px;
  }
  .LandingPageFrameSection_frameContent__3o6ci {
    padding: 56px 48px;
  }
}

.LandingPageMeetTheArtist_containerWrapper__PWelO {
  padding: 40px 20px;
  background-color: var(--primaryDarkColor);
}

.LandingPageMeetTheArtist_container__1tdo2 {
  padding-bottom: 24px;
  text-align: center;
  margin: auto;
  position: relative;
}

p.LandingPageMeetTheArtist_heading__3vQ4R {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

h4.LandingPageMeetTheArtist_title__3_V2T {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.LandingPageMeetTheArtist_textUpperCase__2ajqA {
  text-transform: uppercase;
}

div.LandingPageMeetTheArtist_carouselContainer__2HvWS {
  height: auto;
}

.LandingPageMeetTheArtist_carouselContainer__2HvWS {
  padding-top: 8px;
  padding-bottom: 32px;
}

p.LandingPageMeetTheArtist_info__3Qb-z {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

a.LandingPageMeetTheArtist_interview__1nS5v {
  display: block;
  font-family: 'SamsungOne' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
  letter-spacing: 0.1px;
  margin-top: 36px;
  text-decoration: none;
}

.LandingPageMeetTheArtist_btn__1TNy8 {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: #585DE9;
  border-radius: 4px;
  margin: 24px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}

.LandingPageMeetTheArtist_btn__1TNy8:hover,
.LandingPageMeetTheArtist_btn__1TNy8:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
}

.LandingPageMeetTheArtist_btnText__3-lYq {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  -webkit-user-select: none;
          user-select: none;
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .LandingPageMeetTheArtist_container__1tdo2 {
    width: 70%;
    padding-bottom: 36px;
  }
  .LandingPageMeetTheArtist_containerWrapper__PWelO {
    padding: 56px 0 54px 0;
  }
  p.LandingPageMeetTheArtist_heading__3vQ4R {
    font-size: 16px;
    line-height: 24px;
  }
  h4.LandingPageMeetTheArtist_title__3_V2T {
    font-size: 36px;
    line-height: 44px;
  }
  p.LandingPageMeetTheArtist_info__3Qb-z {
    font-size: 16px;
    line-height: 24px;
  }
  .LandingPageMeetTheArtist_mediaContainer__1r7ES {
    margin: 36px 0;
  }
  .LandingPageMeetTheArtist_carouselContainer__2HvWS {
    padding-bottom: 28px;
  }
  .LandingPageMeetTheArtist_btn__1TNy8 {
    margin: 26px auto 0;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .LandingPageMeetTheArtist_container__1tdo2 {
    width: 60%;
    padding-bottom: 48px;
  }
  .LandingPageMeetTheArtist_containerWrapper__PWelO {
    padding: 64px 0 66px 0;
  }
  p.LandingPageMeetTheArtist_heading__3vQ4R {
    font-size: 22px;
    line-height: 28px;
  }
  h4.LandingPageMeetTheArtist_title__3_V2T {
    font-size: 45px;
    line-height: 56px;
  }
  p.LandingPageMeetTheArtist_info__3Qb-z {
    font-size: 16px;
    line-height: 24px;
  }
  .LandingPageMeetTheArtist_mediaContainer__1r7ES {
    margin: 48px 0;
  }
  .LandingPageMeetTheArtist_carouselContainer__2HvWS {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .LandingPageMeetTheArtist_btn__1TNy8 {
    margin: 48px auto 0;
  }
}

/** 
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .LandingPageMeetTheArtist_container__1tdo2 {
    width: 50%;
  }
  .LandingPageMeetTheArtist_containerWrapper__PWelO {
    padding: 64px 0 64px 0;
  }
  p.LandingPageMeetTheArtist_heading__3vQ4R {
    font-size: 22px;
    line-height: 28px;
  }
  h4.LandingPageMeetTheArtist_title__3_V2T {
    font-size: 45px;
    line-height: 56px;
  }
  p.LandingPageMeetTheArtist_info__3Qb-z {
    font-size: 16px;
    line-height: 24px;
  }
  .LandingPageMeetTheArtist_mediaContainer__1r7ES {
    margin: 48px 0;
  }
  .LandingPageMeetTheArtist_carouselContainer__2HvWS {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .LandingPageMeetTheArtist_btn__1TNy8 {
    margin: 48px auto 0;
  }
}

/** 
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .LandingPageMeetTheArtist_container__1tdo2 {
    width: 40%;
  }
  .LandingPageMeetTheArtist_containerWrapper__PWelO {
    padding: 64px 0 64px 0;
  }
  p.LandingPageMeetTheArtist_heading__3vQ4R {
    font-size: 22px;
    line-height: 28px;
  }
  h4.LandingPageMeetTheArtist_title__3_V2T {
    font-size: 45px;
    line-height: 56px;
  }
  p.LandingPageMeetTheArtist_info__3Qb-z {
    font-size: 16px;
    line-height: 24px;
  }
  .LandingPageMeetTheArtist_mediaContainer__1r7ES {
    margin: 48px 0;
  }
  .LandingPageMeetTheArtist_carouselContainer__2HvWS {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .LandingPageMeetTheArtist_btn__1TNy8 {
    margin: 48px auto 0;
  }
}

.swiper.meetTheArtistSwiper {
  position: static;
}

.meetTheArtistSwiper .swiper-button-prev,
.meetTheArtistSwiper .swiper-button-next {
  display: none;
  background-color: #585DE9;
  border: 2px solid #585DE9;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 40.3%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  font-family: inherit;
  color: var(--primaryColor);
  background-position: center;
  background-repeat: no-repeat;
  transition-property: background-color, background;
  transition-duration: 0.2s;
}

.meetTheArtistSwiper .swiper-button-prev {
  background-image: url('/images/carousel_backward_white.svg');
}

.meetTheArtistSwiper .swiper-button-next {
  background-image: url('/images/carousel_forward_white.svg');
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
}

.meetTheArtistSwiper .swiper-button-next:hover,
.meetTheArtistSwiper .swiper-button-next:focus {
  background: url('/images/carousel_forward_white.svg'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #6c70ed;
}

.meetTheArtistSwiper .swiper-button-prev:hover,
.meetTheArtistSwiper .swiper-button-prev:focus {
  background: url('/images/carousel_backward_white.svg'),
    linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #6c70ed;
}

.meetTheArtistSwiper .swiper-button-disabled {
  background-color: #585DE9;
}

.meetTheArtistSwiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 0.4;
  background-color: #fff;
}

.meetTheArtistSwiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #fff;
}

/** 
  *** SMALL ***
  **/
@media screen and (min-width: 600px) {
  .meetTheArtistSwiper .swiper-button-prev,
  .meetTheArtistSwiper .swiper-button-next {
    display: block;
  }

  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/** 
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .meetTheArtistSwiper .swiper-button-prev,
  .meetTheArtistSwiper .swiper-button-next {
    top: 43%;
  }
  .swiper-button-prev {
    left: -20%;
  }

  .swiper-button-next {
    right: -20%;
  }
}

/** 
    *** LARGE ***
    **/
@media screen and (min-width: 1200px) {
  .meetTheArtistSwiper .swiper-button-prev,
  .meetTheArtistSwiper .swiper-button-next {
    top: 44.7%;
  }
  .swiper-button-prev {
    left: -15%;
  }

  .swiper-button-next {
    right: -15%;
  }
}

/**
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .meetTheArtistSwiper .swiper-button-prev,
  .meetTheArtistSwiper .swiper-button-next {
    top: 43.4%;
  }
}

/**
*** EXTRA-Extra-LARGE ***
**/
@media screen and (min-width: 1920px) {
  .meetTheArtistSwiper .swiper-button-prev,
  .meetTheArtistSwiper .swiper-button-next {
    top: 46.7%;
  }
}
.youtube-embed_container__OR91Y {
  overflow: hidden;
  padding: 0;
  margin: 24px 0;
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: var(--aspect-ratio);
  object-fit: cover;
  border-radius: 8px;
}
.youtube-embed_container__OR91Y iframe {
  border: none;
}

.youtube-embed_container__OR91Y:hover > .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButtonWrapper__1TaU5 {
  transition: all 1s ease;
  background-color: rgba(88, 93, 233, 1);
}

.youtube-embed_ytEmbed__2sWo2 {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}
.youtube-embed_ytEmbed__2sWo2.youtube-embed_ytLoaded__2hccJ::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.youtube-embed_ytEmbed__2sWo2::after {
  content: '';
  display: block;
  padding-bottom: var(--aspect-ratio);
}
.youtube-embed_ytEmbed__2sWo2 > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButtonWrapper__1TaU5 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  background-color: rgba(88, 93, 233, 0.7);
  border-radius: 50%;
  width: 10.5vw;
  height: 10.5vw;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2vw;
}

.youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButton__2ENmk {
  background: none;
}

.youtube-embed_ytEmbed__2sWo2.youtube-embed_ytLoaded__2hccJ {
  cursor: unset;
}

.youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytLoader__73N4v {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  width: 12.5vw;
  height: 12.5vw;
  padding: 1vw;
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButtonWrapper__1TaU5 {
    width: 10.5vw;
    height: 10.5vw;
    padding: 2vw;
  }

  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytLoader__73N4v {
    width: 12.5vw;
    height: 12.5vw;
    padding: 1vw;
  }

  .youtube-embed_container__OR91Y {
    border-radius: 16px;
  }
}

/** 
  *** MEDIUM ***
  **/
@media screen and (min-width: 900px) {
  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButtonWrapper__1TaU5 {
    width: 8.5vw;
    height: 8.5vw;
    padding: 1.8vw;
  }

  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytLoader__73N4v {
    width: 9.5vw;
    height: 9.5vw;
    padding: 1.2vw;
  }

  .youtube-embed_container__OR91Y {
    border-radius: 16px;
  }
}

/** 
  *** LARGE ***
  **/
@media screen and (min-width: 1200px) {
  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButtonWrapper__1TaU5 {
    width: 7.3vw;
    height: 7.3vw;
    padding: 1.6vw;
  }

  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytLoader__73N4v {
    width: 7.3vw;
    height: 7.3vw;
    padding: 1.2vw;
  }

  .youtube-embed_container__OR91Y {
    border-radius: 16px;
  }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytPlayButtonWrapper__1TaU5 {
    width: 6vw;
    height: 6vw;
    padding: 1.4vw;
  }

  .youtube-embed_ytEmbed__2sWo2 > .youtube-embed_ytLoader__73N4v {
    width: 6vw;
    height: 6vw;
    padding: 1vw;
  }

  .youtube-embed_container__OR91Y {
    border-radius: 16px;
  }
}

.styles_container__3d2gH {
    position: relative;
    padding: 40px 20px;
    text-align: center;
    overflow: hidden;
}

.styles_link__1FGFm {
    color: inherit;
    text-decoration: none;
}

.styles_link__1FGFm:hover {
    -webkit-text-decoration: underline #9a99b0;
            text-decoration: underline #9a99b0;
}

.styles_chevronIcon__19vuB {
    margin-left: 2px;
    display: inline-block;
    vertical-align: text-top;
    height: 10px;
    width: 10px;
}

h4.styles_header__HmQ1H {
    margin-bottom: 16px;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
}

.styles_subHeadingWrapper__2ugEl {
    text-align: justify;
    margin-bottom: 16px;
}

p.styles_searchHeader__BbNSm {
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
}

p.styles_subHeading__2NADU {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-right: 10px;
}

.styles_artworkItemSkeleton__CDB_f {
    margin-top: 24px;
}

.styles_artworkContainer__37c_H {
    text-align: left;
    color: #777;
}

.styles_sixteenByNineArtworkContainer__meCXK {
    margin-top: 24px;
}

.styles_artworkItem__1jvFd {
    margin-top: 16px;
    overflow: hidden;
}

.styles_nineBySixteenArtworkContainer__3iQw1 .styles_artworkItem__1jvFd {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.styles_imgContainer__4lpG6 {
    overflow: hidden;
    margin-bottom: 8px;
}

.styles_imgContainer__4lpG6 img {
    display: block;
    width: 100%;
}

.styles_bullet__1dCoi {
    display: none;
    margin: 0 4px;
}

span.styles_artworkText__2qRxk {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    display: block;
    margin-right: 16px;
}

.styles_artworkName__3qIDF {
    display: block;
    color: #000000;
}

.styles_artistName__1LhAT {
    display: block;
    color: #9a99b0;
}

.styles_textAndIconWrapper__3nQEI {
    display: flex;
}

.styles_favoriteIcon__1W4tR {
    margin-left: auto;
    height: 24px;
    width: 24px;
}

.styles_favoriteIconPortrait__3zNsM {
    display: block;
    margin-top: 4px;
    height: 24px;
    width: 24px;
}

.styles_searchField__2paDl {
    margin-bottom: 40px;
}

button.styles_searchBtn__1WmkG {
    margin-top: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    width: 145px;
    height: 40px;
    background: #ffffff;
    border: 2px solid #cbcddc;
    border-radius: 4px;
    text-decoration: none;
    overflow: hidden;
    transition: background 0.2s;
    text-transform: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--primaryColor);
    -webkit-user-select: none;
            user-select: none;
}

.styles_searchBtn__1WmkG:hover,
.styles_searchBtn__1WmkG:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
    .styles_container__3d2gH {
        padding: 56px 140px 52px;
    }
    h4.styles_header__HmQ1H {
        margin-bottom: 24px;
        font-size: 36px;
        line-height: 44px;
    }
    .styles_subHeadingWrapper__2ugEl {
        margin-bottom: 24px;
    }
    p.styles_searchHeader__BbNSm {
        font-size: 22px;
        line-height: 20px;
    }
    p.styles_subHeading__2NADU {
        font-size: 16px;
        line-height: 24px;
    }

    .styles_sixteenByNineArtworkContainer__meCXK {
        margin-top: 32px;
    }

    .styles_artworkItem__1jvFd {
        margin-top: 24px;
    }

    .styles_nineBySixteenArtworkContainer__3iQw1 {
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        -webkit-column-gap: 30px;
                column-gap: 30px;
    }

    .styles_nineBySixteenArtworkContainer__3iQw1 .styles_artworkItem__1jvFd {
        width: auto;
    }

    .styles_nineBySixteenArtworkContainer__3iQw1 .styles_bullet__1dCoi {
        display: none;
    }

    .styles_bullet__1dCoi {
        display: inline;
    }

    span.styles_artworkText__2qRxk {
        flex-direction: row;
    }

    .styles_sixteenByNineArtworkContainer__meCXK .styles_artworkName__3qIDF,
    .styles_sixteenByNineArtworkContainer__meCXK .styles_artistName__1LhAT {
        display: inline;
    }

    .styles_searchForm__3Zbvc {
        display: flex;
    }

    button.styles_searchBtn__1WmkG {
        margin-top: 0;
    }

    .styles_searchBtn__1WmkG:hover,
    .styles_searchBtn__1WmkG:focus {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
        #ffffff;
    }
}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
    .styles_container__3d2gH {
        padding: 64px 112px;
    }
    h4.styles_header__HmQ1H {
        font-size: 45px;
        line-height: 56px;
    }
    p.styles_searchHeader__BbNSm {
        font-size: 24px;
        line-height: 26px;
    }
    .styles_artworkItemSkeleton__CDB_f {
        flex-basis: calc(100% / 2 - 24px);
    }

    .styles_sixteenByNineArtworkContainer__meCXK {
        margin-top: 40px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        -webkit-column-gap: 24px;
                column-gap: 24px;
    }

    .styles_artworkItem__1jvFd {
        margin-top: 24px;
    }

    .styles_nineBySixteenArtworkContainer__3iQw1 {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        -webkit-column-gap: 20px;
                column-gap: 20px;
    }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
    .styles_container__3d2gH {
        padding: 64px 212px;
    }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
    .styles_container__3d2gH {
        padding: 64px 340px;
    }
}

.CopyToClipboardButton_btn__3Xkeu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.CopyToClipboardButton_btn__3Xkeu:active {
    background-color: rgba(0, 0, 0, 0.3);
}
.signInTooltipCta_signInCta__jFdsa {
    font-weight: bolder;
    font-style: italic;
    cursor: pointer;
}

.signInTooltipCta_signInCta__jFdsa:hover {
    text-decoration: underline;
}
.PageNotFound_container__1K-o4 {
  height: 100vh;
  padding: 1rem;
  background-color: #1D1D2F;
  color:#fff;
}

.PageNotFound_logo__2qEiN {
  overflow: hidden;
  width: 10%;
  transition: width 0.2s linear;
}

.PageNotFound_logo__2qEiN img {
  width: 100%;
  display: block;
}

.PageNotFound_header__86FMR {
  font-weight: 800 !important;
  margin-top: 5rem !important;
  margin-bottom: 1rem !important;
}

.PageNotFound_subtitle__bbKR4 {
  line-height: 0.85 !important;
}

.PageNotFound_mbottom__1FGQm {
  margin-bottom: 5rem !important;
}

.PageNotFound_btn__1sWXy {
  display: block;
  color: inherit;
  text-decoration: none;
  border: 1px solid #000;
  padding: 1rem 1.5rem;
}

@media screen and (min-width: 600px) {
  .PageNotFound_logo__2qEiN {
    width: 6%;
  }
  .PageNotFound_header__86FMR {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 900px) {
  .PageNotFound_logo__2qEiN {
    width: 4%;
  }
  .PageNotFound_header__86FMR {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .PageNotFound_logo__2qEiN {
    width: 3%;
  }
  .PageNotFound_header__86FMR {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 1536px) {
  .PageNotFound_logo__2qEiN {
    width: 2%;
  }
  .PageNotFound_mbottom__1FGQm {
    margin-bottom: 3rem !important;
  }
}

.Button_btnPrimary__60wlR {
    display: block;
    background-color: #585DE9;
    color:inherit;
    text-decoration: none;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
  }
.TermsAndConditionsPage_container__3vFLR {
  line-height: 2;
  padding: 2rem;
  padding-left: 1rem;
  margin-top: 2rem;
}

.TermsAndConditionsPage_container__3vFLR h1,
.TermsAndConditionsPage_container__3vFLR h2 {
  line-height: 1.5;
}

.TermsAndConditionsPage_container__3vFLR li {
  margin-bottom: 1rem;
}

.TermsAndConditionsPage_listTypeNone__EYZSH {
  list-style: none;
}

.TermsAndConditionsPage_pdRight1rem__1IrNr {
  padding-right: 1rem;
}

.TermsAndConditionsPage_alphabetBullets__3ziGW {
  list-style-type: lower-alpha;
}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
  .TermsAndConditionsPage_container__3vFLR {
    padding: 0 2rem 4rem;
  }
}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
  .TermsAndConditionsPage_container__3vFLR {
    padding: 0 4rem 6rem;
  }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
  .TermsAndConditionsPage_container__3vFLR {
    padding: 64px 212px;
  }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
  .TermsAndConditionsPage_container__3vFLR {
    padding: 64px 340px;
  }
}

.CollectionDetailPage_containerWrapper__AgJUq {
    overflow: hidden;
}

.CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_detailsContainerWrapper__wuB7b {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CollectionDetailPage_container__LLdJf {
    position: relative;
    padding: 40px 20px 64px;
    text-align: center;
    overflow: hidden;
}

.CollectionDetailPage_subtitleSection__1mw41 {
    text-align: left;
    padding-top: 4px;
}

.CollectionDetailPage_title__VY0do {
    padding: 4px 0;
    text-align: left;
}

p.CollectionDetailPage_heading__1XoyS {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

p.CollectionDetailPage_subHeading__3-7wS {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.CollectionDetailPage_icon__RD4eb {
    margin-left: 10px;
    cursor: pointer;
}

.CollectionDetailPage_detailsSection__1Wqig {
    margin-top: 16px;
    text-align: left;
}

p.CollectionDetailPage_descriptionHeading__2g99W {
    font-weight: 700;
    font-size: 14px;
}

p.CollectionDetailPage_description__CMrZA {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

h4.CollectionDetailPage_header__1Ss4O {
    margin-bottom: 16px;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
}

.CollectionDetailPage_imgContainer__3MkRU {
    overflow: hidden;
    margin-bottom: 20px;
}

.CollectionDetailPage_imgContainer__3MkRU img {
    display: block;
}

.CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_imgContainer__3MkRU img {
    width: 100%;
    height: auto;
}

.CollectionDetailPage_nineBySixteenContainer__2wUiy {
    display: none;
}

.CollectionDetailPage_nineBySixteenLargeContainer__2vCJT {
    display: none;
}

.CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_imgContainer__3MkRU img {
    margin: auto;
    width: 100%;
}

.CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_imgContainer__3MkRU {
    margin: 0 auto 16px;
}

.CollectionDetailPage_imgWrapper__RlvGx {
    overflow: hidden;
    width: auto;
    margin: auto;
}

.CollectionDetailPage_noImgContainer__Z8vOU {
    overflow: hidden;
    margin-bottom: 8px;
}

.CollectionDetailPage_noImgContainer__Z8vOU img {
    display: block;
    width: 100%;
}

.CollectionDetailPage_row__1HfMw {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.CollectionDetailPage_titleColumn__18LNk {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 5 1;
}

.CollectionDetailPage_iconColumn__iU3MH {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1 1;
}

.CollectionDetailPage_centerDiv__3h2k4 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.CollectionDetailPage_titleAndIconSectionWrapper__c0Lrs {
    display: flex;
}

.CollectionDetailPage_titleSectionWrapper__27QSE {
    margin-right: 16px;
}

.CollectionDetailPage_favoriteIcon__SL_Nm {
    margin-left: auto;
    padding-top: 4px;
    height: 24px;
    width: 24px;
}

.CollectionDetailPage_link__F2zbK {
    display: inline-block;
    color: inherit;
    text-decoration: none;
}

.CollectionDetailPage_link__F2zbK:hover {
    text-decoration: underline;
}

.CollectionDetailPage_chevronIcon__3WdkP {
    margin-left: 2px;
    display: inline-block;
    height: 10px;
    width: 10px;
}

/**
*** Extra SMALL ***
*/
@media screen and (min-width: 350px) {
    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_imgContainer__3MkRU {
        width: 350px;
    }

    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_container__LLdJf {
        width: 350px;
        margin: 40px auto 64px;
        padding: 0;
    }
}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
    .CollectionDetailPage_container__LLdJf {
        padding: 56px 165px 64px;
    }

    h4.CollectionDetailPage_header__1Ss4O {
        margin-bottom: 24px;
        font-size: 36px;
        line-height: 44px;
    }

    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_imgContainer__3MkRU {
        width: 480px;
    }

    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_container__LLdJf {
        width: 480px;
        margin: 56px auto 64px;
    }
}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
    .CollectionDetailPage_container__LLdJf {
        padding: 64px 220px;
    }

    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_imgContainer__3MkRU {
        width: 960px;
        height: 540px;
    }

    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_container__LLdJf {
        width: 960px;
        margin: 64px auto;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_container__LLdJf {
        padding: 64px 112px;
    }

    h4.CollectionDetailPage_header__1Ss4O {
        font-size: 45px;
        line-height: 56px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_titleSection__2kB-r {
        width: auto;
    }

    p.CollectionDetailPage_heading__1XoyS {
        font-size: 22px;
        line-height: 28px;
    }

    .CollectionDetailPage_subtitleSection__1mw41 {
        padding-top: 2px;
    }

    .CollectionDetailPage_imgContainer__3MkRU {
        margin-bottom: 18px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_imgContainer__3MkRU {
        margin: 0 80px 0 0;
        width: 455px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_containerWrapper__AgJUq {
        padding-top: 152px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_iconSection__boUro {
        width: 32px;
        margin-bottom: 16px;
        cursor: pointer;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_title__VY0do {
        padding: 0;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_subtitleSection__1mw41 {
        padding-top: 4px;
        margin-bottom: 8px;
    }

    .CollectionDetailPage_nineBySixteenSmallContainer__2enHR {
        display: none;
    }

    .CollectionDetailPage_nineBySixteenLargeContainer__2vCJT {
        display: flex;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_favoriteIcon__SL_Nm {
        padding-top: 0;
        margin-left: 0;
    }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
    .CollectionDetailPage_container__LLdJf {
        padding: 64px 320px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_container__LLdJf {
        padding: 64px 200px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_imgContainer__3MkRU {
        margin: 0 92px 0 0;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_containerWrapper__AgJUq {
        padding-top: 161px;
    }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
    .CollectionDetailPage_container__LLdJf {
        padding: 64px 448px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_container__LLdJf {
        padding: 64px 204px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_imgContainer__3MkRU {
        margin: 0 120px 0 0;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_containerWrapper__AgJUq {
        padding-top: 191px;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_detailsSection__1Wqig {
        width: 648px;
    }
}

@media screen and (min-width: 1920px) {
    .CollectionDetailPage_contentBody__2IPjS {
        height: 90vh;
        display: flex;
    }

    .CollectionDetailPage_sixteenByNine__3Up3h .CollectionDetailPage_contentBody__2IPjS {
        align-items: center;
        justify-content: center;
    }

    .CollectionDetailPage_nineBySixteen__3bFqi .CollectionDetailPage_contentBody__2IPjS {
        align-items: center;
    }
}
.rws-backdrop {
    justify-content: center !important;
}
.UploadImagePage_containerWrapper__QaoyF {
    overflow: hidden;
}

.UploadImagePage_container__jAKtj {
    position: relative;
    padding: 40px 20px 64px;
    text-align: center;
    overflow: hidden;
}

.UploadImagePage_subtitleSection__Sbb24 {
    text-align: left;
    padding-top: 4px;
}

.UploadImagePage_title__3V3dj {
    padding: 4px 0;
    text-align: left;
}

.UploadImagePage_titleInfoSection__2RrKv {
    text-align: center;
    padding-top: 14px;
    font-size: 12px;
    font-weight: 700;
}

/**
*** Extra SMALL ***
*/
@media screen and (min-width: 350px) {
    .UploadImagePage_sixteenByNine__3l8CD .UploadImagePage_imgContainer__1WdJw {
        width: 350px;
    }

}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
    .UploadImagePage_container__jAKtj {
        padding: 56px 165px 64px;
    }


}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
    .UploadImagePage_container__jAKtj {
        padding: 64px 220px;
    }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
    .UploadImagePage_container__jAKtj {
        padding: 64px 320px;
    }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
    .UploadImagePage_container__jAKtj {
        padding: 64px 448px;
    }


}

@media screen and (min-width: 1920px) {
    .UploadImagePage_contentBody__3fmqh {
        height: 90vh;
        display: flex;
    }
}
.FallbackErrorPage_container__1Y_Mk {
  height: 100vh;
  padding: 1rem;
  background-color: #1D1D2F;
  color:#fff;
}

.FallbackErrorPage_logo__3OYr6 {
  overflow: hidden;
  width: 10%;
  transition: width 0.2s linear;
}

.FallbackErrorPage_logo__3OYr6 img {
  width: 100%;
  display: block;
}

.FallbackErrorPage_header__2nUde {
  font-weight: 800 !important;
  margin-top: 5rem !important;
  margin-bottom: 1rem !important;
}

.FallbackErrorPage_btn__1xN5C {
  display: block;
  color: inherit;
  text-decoration: none;
  border: 1px solid #000;
  padding: 1rem 1.5rem;
}

@media screen and (min-width: 600px) {
  .FallbackErrorPage_logo__3OYr6 {
    width: 6%;
  }
  .FallbackErrorPage_header__2nUde {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 900px) {
  .FallbackErrorPage_logo__3OYr6 {
    width: 4%;
  }
  .FallbackErrorPage_header__2nUde {
    margin-top: 2rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .FallbackErrorPage_logo__3OYr6 {
    width: 3%;
  }
  .FallbackErrorPage_header__2nUde {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 1536px) {
  .FallbackErrorPage_logo__3OYr6 {
    width: 2%;
  }
}

