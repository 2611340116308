.container {
    position: relative;
    padding: 40px 20px;
    text-align: center;
    overflow: hidden;
}

.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline #9a99b0;
}

.chevronIcon {
    margin-left: 2px;
    display: inline-block;
    vertical-align: text-top;
    height: 10px;
    width: 10px;
}

h4.header {
    margin-bottom: 16px;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
}

.subHeadingWrapper {
    text-align: justify;
    margin-bottom: 16px;
}

p.searchHeader {
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
}

p.subHeading {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-right: 10px;
}

.artworkItemSkeleton {
    margin-top: 24px;
}

.artworkContainer {
    text-align: left;
    color: #777;
}

.sixteenByNineArtworkContainer {
    margin-top: 24px;
}

.artworkItem {
    margin-top: 16px;
    overflow: hidden;
}

.nineBySixteenArtworkContainer .artworkItem {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.imgContainer {
    overflow: hidden;
    margin-bottom: 8px;
}

.imgContainer img {
    display: block;
    width: 100%;
}

.bullet {
    display: none;
    margin: 0 4px;
}

span.artworkText {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    display: block;
    margin-right: 16px;
}

.artworkName {
    display: block;
    color: #000000;
}

.artistName {
    display: block;
    color: #9a99b0;
}

.textAndIconWrapper {
    display: flex;
}

.favoriteIcon {
    margin-left: auto;
    height: 24px;
    width: 24px;
}

.favoriteIconPortrait {
    display: block;
    margin-top: 4px;
    height: 24px;
    width: 24px;
}

.searchField {
    margin-bottom: 40px;
}

button.searchBtn {
    margin-top: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    width: 145px;
    height: 40px;
    background: #ffffff;
    border: 2px solid #cbcddc;
    border-radius: 4px;
    text-decoration: none;
    overflow: hidden;
    transition: background 0.2s;
    text-transform: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: var(--primaryColor);
    user-select: none;
}

.searchBtn:hover,
.searchBtn:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
}

/**
*** SMALL ***
*/
@media screen and (min-width: 600px) {
    .container {
        padding: 56px 140px 52px;
    }
    h4.header {
        margin-bottom: 24px;
        font-size: 36px;
        line-height: 44px;
    }
    .subHeadingWrapper {
        margin-bottom: 24px;
    }
    p.searchHeader {
        font-size: 22px;
        line-height: 20px;
    }
    p.subHeading {
        font-size: 16px;
        line-height: 24px;
    }

    .sixteenByNineArtworkContainer {
        margin-top: 32px;
    }

    .artworkItem {
        margin-top: 24px;
    }

    .nineBySixteenArtworkContainer {
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
    }

    .nineBySixteenArtworkContainer .artworkItem {
        width: auto;
    }

    .nineBySixteenArtworkContainer .bullet {
        display: none;
    }

    .bullet {
        display: inline;
    }

    span.artworkText {
        flex-direction: row;
    }

    .sixteenByNineArtworkContainer .artworkName,
    .sixteenByNineArtworkContainer .artistName {
        display: inline;
    }

    .searchForm {
        display: flex;
    }

    button.searchBtn {
        margin-top: 0;
    }

    .searchBtn:hover,
    .searchBtn:focus {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
        #ffffff;
    }
}

/**
*** MEDIUM ***
*/
@media screen and (min-width: 900px) {
    .container {
        padding: 64px 112px;
    }
    h4.header {
        font-size: 45px;
        line-height: 56px;
    }
    p.searchHeader {
        font-size: 24px;
        line-height: 26px;
    }
    .artworkItemSkeleton {
        flex-basis: calc(100% / 2 - 24px);
    }

    .sixteenByNineArtworkContainer {
        margin-top: 40px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 24px;
    }

    .artworkItem {
        margin-top: 24px;
    }

    .nineBySixteenArtworkContainer {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
    }
}

/**
*** LARGE ***
*/
@media screen and (min-width: 1200px) {
    .container {
        padding: 64px 212px;
    }
}

/**
*** EXTRA LARGE ***
*/
@media screen and (min-width: 1536px) {
    .container {
        padding: 64px 340px;
    }
}
