.Logo {
  text-align: center;
  padding: 14px 0;
  flex-grow: 1;
  background-color: var(--primaryDarkColor);
}

.profileIcon {
  margin: 12px 16px 12px 0;
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.HeaderBorderBottom {
  border-bottom: 1px solid #3e3e52;
}

.BackArrow {
  background-color: var(--primaryDarkColor);
}

.BackArrow img {
  padding: 16px;
  padding-left: 24px;
  cursor: pointer;
}

.ProfileIcon {
  background-color: var(--primaryDarkColor);
}

.ProfileIcon img {
  margin-right: 25px;
  width: 15px;
  height: 18px;
  cursor: pointer;
}

.ProfileAvatar {
  margin-right: 25px;
  width: 15px;
  height: 18px;
  cursor: pointer;
}

.LeftSpace {
  padding: 25px
}

.LoginAccount {
  background-color: var(--primaryDarkColor);
  align-items: left;
}

.LoginAccount img {
  padding: 16px;
  padding-left: 24px;
  cursor: pointer;
}

.anchorElement {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.anchorContainerFlex {
  display: flex;
  align-items: center;
  background-color: var(--primaryDarkColor);
}

.anchorContainerGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.loginLinkItem {
  text-decoration: none;
  text-decoration-skip-ink: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--primaryDarkColor);
}

.loginLinkItem:hover {
  text-decoration: underline;
}