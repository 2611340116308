.containerWrapper {
  padding: 40px 20px;
}

.container {
  padding-bottom: 30px;
  text-align: center;
  margin: auto;
  position: relative;
}

.featureListcontainer {
  height: 600px;
  width: 100%;
}

p.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}

p.headingWhite {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

h4.title {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

h4.titleWhite {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

h4.header {
  margin-top: 16px;
  margin-bottom: 16px;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
}

.textUpperCase {
  text-transform: uppercase;
}

div.carouselContainer {
  height: auto;
  padding-left: 1px;
  padding-right: 1px;
}

.carouselContainer {
  padding-top: 8px;
}

.imgContainer {
  overflow: hidden;
  padding: 0;
  margin: 24px 0;
  border-radius: 8px;
}

p.info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.imgContainer img {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #cbcddc;
  border-radius: 4px;
  margin: 30px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}

.btnBlue {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: #585DE9;
  border-radius: 4px;
  margin: 24px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}


.btn:hover,
.btn:focus {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
}

.btnBlue:hover,
.btnBlue:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
}

.btnDisabled {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    #ffffff;
  border: 2px solid #cbcddc;
  border-radius: 4px;
  margin: 30px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}

.btnText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--primaryColor);
  user-select: none;
}

.btnTextWhite {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  user-select: none;
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .container {
    width: 70%;
  }

  .containerWrapper {
    padding: 56px 0 54px 0;
  }

  .featureListcontainer {
    height: 300px;
    width: 100%;
  }

  p.heading {
    font-size: 16px;
    line-height: 24px;
  }

  p.headingWhite {
    font-size: 16px;
    line-height: 24px;
  }

  h4.title {
    font-size: 36px;
    line-height: 44px;
  }

  h4.titleWhite {
    font-size: 36px;
    line-height: 44px;
  }

  h4.header {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 44px;
  }

  p.info {
    font-size: 16px;
    line-height: 24px;
  }

  .imgContainer {
    margin: 36px 0;
    border-radius: 16px;
  }

  .btn {
    margin: 26px auto 0;
  }

  .btnDisabled {
    margin: 26px auto 0;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .container {
    width: 60%;
  }

  .featureListcontainer {
    height: 300px;
    width: 100%;
  }

  .containerWrapper {
    padding: 64px 0 66px 0;
  }

  p.heading {
    font-size: 22px;
    line-height: 28px;
  }

  p.headingWhite {
    font-size: 22px;
    line-height: 28px;
  }

  h4.title {
    font-size: 45px;
    line-height: 56px;
  }

  h4.titleWhite {
    font-size: 45px;
    line-height: 56px;
  }

  h4.header {
    font-size: 45px;
    line-height: 56px;
  }

  p.info {
    font-size: 16px;
    line-height: 24px;
  }

  .imgContainer {
    margin: 48px 0;
    border-radius: 16px;
  }

  .carouselContainer {
    padding-top: 16px;
  }

  .btn {
    margin: 38px auto 0;
  }

  .btnDisabled {
    margin: 38px auto 0;
  }
}

/** 
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .container {
    width: 50%;
  }

  .featureListcontainer {
    height: 600px;
    width: 100%;
  }

  .containerWrapper {
    padding: 64px 0 64px 0;
  }

  p.heading {
    font-size: 22px;
    line-height: 28px;
  }

  p.headingWhite {
    font-size: 22px;
    line-height: 28px;
  }

  h4.title {
    font-size: 45px;
    line-height: 56px;
  }

  h4.titleWhite {
    font-size: 45px;
    line-height: 56px;
  }

  p.info {
    font-size: 16px;
    line-height: 24px;
  }

  .imgContainer {
    margin: 48px 0;
    border-radius: 16px;
  }

  .carouselContainer {
    padding-top: 16px;
  }

  .btn {
    margin: 38px auto 0;
  }

  .btnDisabled {
    margin: 38px auto 0;
  }
}

/** 
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .container {
    width: 40%;
  }

  .featureListcontainer {
    height: 600px;
    width: 100%;
  }

  .containerWrapper {
    padding: 64px 0 64px 0;
  }

  p.heading {
    font-size: 22px;
    line-height: 28px;
  }

  p.headingWhite {
    font-size: 22px;
    line-height: 28px;
  }

  h4.title {
    font-size: 45px;
    line-height: 56px;
  }

  h4.titleWhite {
    font-size: 45px;
    line-height: 56px;
  }

  p.info {
    font-size: 16px;
    line-height: 24px;
  }

  .imgContainer {
    margin: 48px 0;
    border-radius: 16px;
  }

  .carouselContainer {
    padding-top: 16px;
  }

  .btn {
    margin: 38px auto 0;
  }

  .btnDisabled {
    margin: 38px auto 0;
  }
}