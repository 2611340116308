.containerWrapper {
  padding: 40px 20px;
  background-color: var(--primaryDarkColor);
}

.container {
  padding-bottom: 24px;
  text-align: center;
  margin: auto;
  position: relative;
}

p.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

h4.title {
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.textUpperCase {
  text-transform: uppercase;
}

div.carouselContainer {
  height: auto;
}

.carouselContainer {
  padding-top: 8px;
  padding-bottom: 32px;
}

p.info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

a.interview {
  display: block;
  font-family: 'SamsungOne' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
  letter-spacing: 0.1px;
  margin-top: 36px;
  text-decoration: none;
}

.btn {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 145px;
  height: 40px;
  background: #585DE9;
  border-radius: 4px;
  margin: 24px auto 0;
  text-decoration: none;
  overflow: hidden;
  transition: background 0.2s;
}

.btn:hover,
.btn:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #585DE9;
}

.btnText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  user-select: none;
}

/** 
*** SMALL ***
**/
@media screen and (min-width: 600px) {
  .container {
    width: 70%;
    padding-bottom: 36px;
  }
  .containerWrapper {
    padding: 56px 0 54px 0;
  }
  p.heading {
    font-size: 16px;
    line-height: 24px;
  }
  h4.title {
    font-size: 36px;
    line-height: 44px;
  }
  p.info {
    font-size: 16px;
    line-height: 24px;
  }
  .mediaContainer {
    margin: 36px 0;
  }
  .carouselContainer {
    padding-bottom: 28px;
  }
  .btn {
    margin: 26px auto 0;
  }
}

/** 
*** MEDIUM ***
**/
@media screen and (min-width: 900px) {
  .container {
    width: 60%;
    padding-bottom: 48px;
  }
  .containerWrapper {
    padding: 64px 0 66px 0;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  h4.title {
    font-size: 45px;
    line-height: 56px;
  }
  p.info {
    font-size: 16px;
    line-height: 24px;
  }
  .mediaContainer {
    margin: 48px 0;
  }
  .carouselContainer {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .btn {
    margin: 48px auto 0;
  }
}

/** 
*** LARGE ***
**/
@media screen and (min-width: 1200px) {
  .container {
    width: 50%;
  }
  .containerWrapper {
    padding: 64px 0 64px 0;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  h4.title {
    font-size: 45px;
    line-height: 56px;
  }
  p.info {
    font-size: 16px;
    line-height: 24px;
  }
  .mediaContainer {
    margin: 48px 0;
  }
  .carouselContainer {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .btn {
    margin: 48px auto 0;
  }
}

/** 
*** EXTRA-LARGE ***
**/
@media screen and (min-width: 1536px) {
  .container {
    width: 40%;
  }
  .containerWrapper {
    padding: 64px 0 64px 0;
  }
  p.heading {
    font-size: 22px;
    line-height: 28px;
  }
  h4.title {
    font-size: 45px;
    line-height: 56px;
  }
  p.info {
    font-size: 16px;
    line-height: 24px;
  }
  .mediaContainer {
    margin: 48px 0;
  }
  .carouselContainer {
    padding-top: 16px;
    padding-bottom: 40px;
  }
  .btn {
    margin: 48px auto 0;
  }
}
